import React from "react";
import LargeEventCard from "./LargeEventCard/LargeEventCard";
import SmallEventCard from "./SmallEventCard/SmallEventCard";

function EventCard({ isSmallScreen, eventData }) {
  return (
    <>
      {isSmallScreen ? (
        <SmallEventCard eventData={eventData} />
      ) : (
        <LargeEventCard eventData={eventData} />
      )}
    </>
  );
}

export default EventCard;
