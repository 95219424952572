import React from "react";
import { Modal } from "antd";
function AlertModal({ isModalVisible, onCancel, msg }) {
	return (
		<Modal
			visible={isModalVisible}
			onCancel={onCancel}
			footer={[]}
			centered
			className="alertModal text-center "
		>
			<h3 className="my-4">{msg}</h3>
		</Modal>
	);
}

export default AlertModal;
