import React, { Component } from "react";
import { baseURL } from "../../App";
import { connect } from "react-redux";
import HamburgerList from "../../Components/HamburgerList/HamburgerList";
import NavBarLg from "../../Components/NavBarLg/NavBarLg";
import NavBarSm from "../../Components/NavBarSm/NavBarSm";
import HomePageCalendar from "../../Components/HomePageCalendar/HomePageCalendar";
import FilterModal from "../../Components/FilterModal/FilterModal";
import DropDownModal from "../../Components/DropDownModal/DropDownModal";
import CoachCard from "../../Components/CoachCard/CoachCard";
import ArrowLeft from "../../Components/svgLogos/HomePageFigures/ArrowLeft";
import ArrowRight from "../../Components/svgLogos/HomePageFigures/ArrowRight";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";
import FloatingBar from "../../Components/FloatingBar/FloatingBar";
import BookingModalStatus from "../../Components/BookingModalStatus/BookingModalStatus";
import { BookAction } from "../../Store/Actions/BookAction";
import Logo from "../../Assets/BannerLogo.svg";
// import defaultBannerImage from "../../Assets/Welo-graphicBanner-tree.jpg";
import BookingModal from "../../Components/BookingModal/BookingModal";
import Pusher from "pusher-js";
// import avatar from "../../Assets/DefaultImg.png";
// import watchVideo from "../../Assets/watch-video.svg";
import "./HomePage.css";
import "antd/dist/antd.css";
import FullScreenLoading from "../../Components/FullScreenLoading/FullScreenLoading";

class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      coaches: [],
      loadingGurus: false,
      index: 1,
      pagesCount: 0,
      video: null,
      min: 0,
      max: 5,
      toggleFilterPage: localStorage.getItem("filterState")
        ? localStorage.getItem("filterState")
        : false,
      toggleDropdownMenu: false,
      toggleHamburgerMenu: false,
      filteredprops: null,
      currentDatesArr: [],
      theposition: window.pageYOffset,
      calendarPos: 0,
      oneTimeFlag: true,
      paymentMsgModal: false,
      paymentStatus: null,
      name1: null,
      name2: null,
      name3: null,
      subSpecializationName1: null,
      clickedBox: null,
      isClicked: true,
      isClickedSmall: true,
      clickedSmallBox: null,
      DataArray: [],
      firstId: "60b784aeba42b00b94fbf98c",
      secondId: null,
      thirdId: null,
      fspecCatName: null,
      sspecCatName: null,
      tspecCatName: null,
      specialities: [],
      mindSpecialities: [],
      testObject: [],
      searchVal: null,
      timeZone: localStorage.getItem("timeZone")
        ? localStorage.getItem("timeZone")
        : "GMT",
      timeServer: null,
      bookingModalStatus: false,
      mainCategoryFilterId: null,
      allDates: [],
      rerender: false,
      requestASession: false,
      message: null,
      isBookingModalOpened: false,
      fastBookingDetails: null,
      WordInBottomOfBanner: "",
      WordInTopOfBanner: "",
      logo: "",
      bannerImage: "",
      isBannerLoading: true,
    };
    localStorage.removeItem("filterState");
  }
  toggleLevelThree = (e) => {
    this.setState({
      clickedSmallBox: e.target.id,
      isClickedSmall: !this.state.isClickedSmall,
    });
  };

  switchPageWithFilter = (pageCount) => {
    axios({
      method: "post",
      url: baseURL + "/coache/FilterByMainCategory",
      data: {
        mainCategoryId: this.state.mainCategoryFilterId,
        index: pageCount,
        timeZone: this.state.timeZone,
      },
    }).then((res) => {
      this.setState({
        coaches: res.data.coaches,
        loadingGurus: false,
      });
    });
    //this.setState({ index: pageCount + 1 });
    // axios({
    // 	method: "post",
    // 	url: baseURL + "/coache/FilterByMainCategoryCount",
    // 	data: {
    // 		mainCategoryId: this.state.mainCategoryId,
    // 	},
    // }).then((res) => {
    // 	this.setState({
    // 		pagesCount: res.data.count,
    // 	});
    // });
  };

  switchPages = (e) => {
    this.setState({ loadingGurus: true });
    if (this.state.mainCategoryFilterId) {
      this.setState({ index: parseInt(e.target.id) }, () => {
        this.switchPageWithFilter(this.state.index - 1);
      });
    } else {
      this.setState({ index: parseInt(e.target.id) }, () => {
        if (this.state.filteredprops !== null) {
          axios({
            method: "post",
            url: baseURL + "/user/filters?index=" + (this.state.index - 1),
            data: this.state.filteredprops,
          }).then((res) => {
            this.setState({
              coaches: res.data,
              loadingGurus: false,
            });
          });
        } else if (this.state.searchVal !== null) {
          axios({
            method: "post",
            url: baseURL + `/coache/searchnew?timeZone=${this.state.timeZone}`,
            data: {
              search: this.state.searchVal,
              index: JSON.stringify(this.state.index - 1),
            },
          })
            .then((res) => {
              this.setState({
                coaches: res.data,
                loadingGurus: false,
                mainCategoryFilterId: null,
              });
            })
            .catch((err) => {});
        } else {
          this.getCoachList();
        }
      });
    }
  };
  nextPage = () => {
    this.setState({ loadingGurus: true });

    if (this.state.mainCategoryFilterId) {
      this.setState({ index: this.state.index + 1 }, () => {
        this.switchPageWithFilter(this.state.index - 1);
      });
    } else {
      if (this.state.filteredprops !== null) {
        if (this.state.index !== Math.ceil(this.state.pagesCount)) {
          this.setState({ index: this.state.index + 1 }, () => {
            axios({
              method: "post",
              url: baseURL + "/user/filters?index=" + (this.state.index - 1),
              data: this.state.filteredprops,
            }).then((res) => {
              this.setState({
                coaches: res.data,
                loadingGurus: false,
              });
            });
          });
        }
      } else if (this.state.searchVal !== null) {
        if (this.state.index !== Math.ceil(this.state.pagesCount)) {
          this.setState({ index: this.state.index + 1 }, () => {
            axios({
              method: "post",
              url:
                baseURL + `/coache/searchnew?timeZone=${this.state.timeZone}`,
              data: {
                search: this.state.searchVal,
                index: JSON.stringify(this.state.index - 1),
              },
            })
              .then((res) => {
                this.setState({
                  coaches: res.data,
                  loadingGurus: false,
                });
              })
              .catch((err) => {});
          });
        }
      } else {
        if (this.state.index !== Math.ceil(this.state.pagesCount)) {
          this.setState({ index: this.state.index + 1 }, () => {
            this.getCoachList();
          });
        }
      }
    }
  };

  prevPage = () => {
    this.setState({ loadingGurus: true });
    if (this.state.mainCategoryFilterId) {
      this.setState({ index: this.state.index - 1 }, () => {
        this.switchPageWithFilter(this.state.index - 1);
      });
    } else {
      if (this.state.filteredprops !== null) {
        if (this.state.index !== 1) {
          this.setState({ index: this.state.index - 1 }, () => {
            axios({
              method: "post",
              url: baseURL + "/user/filters?index=" + (this.state.index - 1),
              data: this.state.filteredprops,
            }).then((res) => {
              this.setState({
                coaches: res.data,
                loadingGurus: false,
              });
            });
          });
        }
      } else if (this.state.searchVal !== null) {
        if (this.state.index !== 1) {
          this.setState({ index: this.state.index - 1 }, () => {
            axios({
              method: "post",
              url:
                baseURL + `/coache/searchnew?timeZone=${this.state.timeZone}`,
              data: {
                search: this.state.searchVal,
                index: JSON.stringify(this.state.index - 1),
              },
            })
              .then((res) => {
                this.setState({
                  coaches: res.data,
                  loadingGurus: false,
                });
              })
              .catch((err) => {});
          });
        }
      } else {
        if (this.state.index !== 1) {
          this.setState({ index: this.state.index - 1 }, () => {
            this.getCoachList();
          });
        }
      }
    }
  };
  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const el = document.getElementById("homePageCalendar");
    const elDistanceToTop = el?.getBoundingClientRect().top;
    this.setState(
      {
        theposition: winScroll,
        calendarPos: elDistanceToTop,
      },
      () => {
        if (this.state.calendarPos < 0) {
          this.props.getUpcommingSessionHight(true);
        } else {
          this.props.getUpcommingSessionHight(false);
        }
      }
    );
  };
  toggleFilterPage = (val) => {
    this.setState({ toggleFilterPage: val });
  };
  toggleDropdownMenu = (val) => {
    this.setState({ toggleDropdownMenu: val });
  };
  toggleHamburgerMenu = (val) => {
    this.setState({ toggleHamburgerMenu: val });
  };
  filteredCoaches = (val) => {
    this.setState({ coaches: val, filteredPagesMode: true, index: 1 });
  };
  filteredPagesCount = (val) => {
    this.setState({ pagesCount: val });
  };
  filteredprops = (val) => {
    this.setState({ filteredprops: val });
  };
  handleCalendarPages = (val) => {
    this.setState({ min: val, max: val + 5 });
  };
  getAllDates = (val) => {
    this.setState({ allDates: val });
  };
  handleCurrentDatesArr = (val) => {
    this.setState({ currentDatesArr: val });
  };

  searchedCoachList = (val) => {
    this.setState({ coaches: val, mainCategoryFilterId: null });
  };
  togglePaymentMsgModal = () => {
    this.setState({ paymentMsgModal: false }, () => this.props.BookAction(-1));
  };

  componentWillMount() {
    this.setState({ loadingGurus: true });
    this.getCoachList();
    axios({
      method: "get",
      url: baseURL + "/account/pagesCount",
    }).then((res) => {
      this.setState({ pagesCount: res.data.count });
    });

    const transactionState = localStorage.getItem("transactionState");
    if (this.props.auth.authData && transactionState) {
      localStorage.removeItem("transactionState");
      if (transactionState === "Success") {
        this.setState(
          {
            paymentStatus: "Success",
            paymentMsgModal: true,
          },
          () => {
            this.componentDidMount();
          }
        );
      } else {
        this.setState({
          paymentStatus: "Fail",
          paymentMsgModal: true,
        });
      }
    }
  }
  getCoachList = () => {
    axios({
      method: "get",
      url:
        baseURL +
        "/coache/coachesListNew?index=" +
        (this.state.index - 1) +
        "&timeZone=" +
        this.state.timeZone,
    }).then((res) => {
      this.setState({
        coaches: res.data,
        loadingGurus: false,
      });
    });
  };

  componentDidMount() {
    const pusher = new Pusher("5f703903cc23623a0c27", {
      cluster: "eu",
      encrypted: true,
    });
    const channel = pusher.subscribe("Welo");
    pusher.connection.bind("error", function (err) {
      if (err?.error?.data?.code === 4004) {
        console.log("Over limit!");
      }
    });
    const index = this.state.index;
    const timeZone = this.state.timeZone;
    const setCoachList = (res) => {
      this.setState({
        coaches: res.data,
        loadingGurus: false,
      });
    };
    channel.bind("ChangeSchedule", function (data) {
      if (data.message === "reload") {
        axios({
          method: "get",
          url:
            baseURL +
            "/coache/coachesListNew?index=" +
            (index - 1) +
            "&timeZone=" +
            timeZone,
        }).then((res) => {
          setCoachList(res);
        });
      }
    });

    this.props.rerenderAPIsFromApp();
    //Show confirmation message after booking for free
    if (localStorage.getItem("bookingStatus")) {
      this.setState(
        {
          paymentStatus: "Success",
          paymentMsgModal: true,
        },
        () => {
          localStorage.removeItem("bookingStatus");
        }
      );
    } else if (localStorage.getItem("searchVal")) {
      this.setState(
        {
          searchVal: localStorage.getItem("searchVal"),
          mainCategoryFilterId: null,
        },
        () => {
          localStorage.removeItem("searchVal");
          axios({
            method: "post",
            url: baseURL + `/coache/searchnew?timeZone=${this.state.timeZone}`,
            data: {
              search: this.state.searchVal,
              index: JSON.stringify(this.state.index - 1),
            },
          })
            .then((res) => {
              this.setState(
                {
                  coaches: res.data,
                  loadingGurus: false,
                },
                () => {
                  this.setState(
                    { pagesCount: localStorage.getItem("PagesCount") },
                    () => {
                      localStorage.removeItem("PagesCount");
                    }
                  );
                }
              );
            })
            .catch((err) => {});
        }
      );
    }

    window.addEventListener("scroll", this.listenToScroll);
    axios({
      method: "get",
      url: baseURL + "/user/AllCategoryList",
    }).then((res) => {
      this.setState({
        testObject: res.data.data,
      });
    });

    axios({
      method: "get",
      url: baseURL + `/landinPage/getLandingPage`,
    }).then((res) => {
      this.setState(
        {
          bannerImage: baseURL + "/" + res?.data?.data?.bannerImage,
          WordInTopOfBanner: res?.data?.data?.WordInTopOfBanner
            ? res.data.data.WordInTopOfBanner
            : "INTRODUCING",
          logo: res?.data?.data?.logo
            ? baseURL + "/" + res.data.data.logo
            : Logo,
          WordInBottomOfBanner: res?.data?.data?.WordInBottomOfBanner
            ? res.data.data.WordInBottomOfBanner
            : "A WELLNESS PLATFORM",
          //isBannerLoading: false,
        },
        () => {
          const landingPageNode = document.querySelector(".landingPage");
          // Note : if the image return with null the banner bg will be set with a default value
          //        from the css file "Home.css"
          if (res?.data?.data?.bannerImage && landingPageNode) {
            landingPageNode.style.background = `linear-gradient(rgba(73, 72, 72, 0.7), rgba(73, 72, 72, 0.2)), url(${this.state.bannerImage}) top center no-repeat`;
            landingPageNode.style.backgroundSize = "cover";
          }
        }
      );
    });
    axios({
      method: "post",
      url: baseURL + "/account/CategoryListByMainCategoryId",
      data: {
        mainCategoryId: this.state.firstId,
      },
    }).then((res) => {
      res.data.forEach((data) => {
        this.setState({
          specialities: [
            ...this.state.specialities,
            { label: data.categoryName, value: data._id },
          ],
        });
      });
    });
    //booking coming from signin
    if (localStorage.getItem("proceedBook")) {
      //If the user is client not coach
      if (this.props.auth.authData?.userType === 1) {
        this.setState(
          {
            fastBookingDetails: this.props.location.state.sessionInfo,
          },
          () => {
            this.setState({ isBookingModalOpened: true });
          }
        );
      }
      localStorage.removeItem("proceedBook");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.timeServer !== this.props.timeServer) {
      this.setState({
        timeServer: this.props.timeServer,
      });
    }
  }

  onChange = (value, props) => {
    axios({
      method: "post",
      url: baseURL + "/user/filters?index=0",
      data: {
        genderName: "",
        languageName: [],
        price: 0,
        specialization: value[0],
        subSpecialization: [],
        unitName: "",
      },
    }).then((res) => {
      this.setState({
        coaches: res.data,
        loadingGurus: false,
      });
    });
    axios({
      method: "post",
      url: baseURL + "/user/filterPagesCount",
      data: {
        genderName: "",
        languageName: [],
        price: 0,
        specialization: value[0],
        subSpecialization: [],
        unitName: "",
      },
    }).then((res) => {
      this.setState({ pagesCount: res.data.count });
    });
  };

  mainCategoryChange = (e) => {
    var categoryValue = e.target.value;
    // eslint-disable-next-line
    if (this.state.mainCategoryFilterId == categoryValue) categoryValue = null;
    // eslint-disable-next-line
    this.setState({
      mainCategoryFilterId: categoryValue,
      loadingGurus: true,
      index: 1,
    });

    if (categoryValue == null) {
      this.getCoachList();
      axios({
        method: "get",
        url: baseURL + "/account/pagesCount",
      }).then((res) => {
        this.setState({ pagesCount: res.data.count });
      });

      return;
    }

    axios({
      method: "post",
      url: baseURL + "/coache/FilterByMainCategory",
      data: {
        mainCategoryId: categoryValue,
        index: 0,
        timeZone: this.state.timeZone,
      },
    }).then((res) => {
      this.setState({
        coaches: res.data.coaches,
        loadingGurus: false,
      });
    });
    axios({
      method: "post",
      url: baseURL + "/coache/FilterByMainCategoryCount",
      data: {
        mainCategoryId: categoryValue,
      },
    }).then((res) => {
      this.setState({
        pagesCount: res.data.count,
      });
    });
  };
  render(props) {
    //const sessionInfo = this.props.location.state?.sessionInfo;
    //const isClient = this.props.auth?.authData?.userType === "1";
    if (localStorage.getItem("refresh")) {
      localStorage.removeItem("refresh");
      this.componentDidMount();
      // window.location.reload();
    }
    const Bodyoption = [];
    const Mindoption = [];
    const Careeroptions = [];

    this.state.testObject.forEach((time) => {
      if (time.mainCategoryId === "60b784c8ba42b00b94fbf98d") {
        time.categories.forEach((Mind) => {
          Mindoption.push({
            value: this.state.testObject.length > 0 && Mind.categoryName,
            label: this.state.testObject.length > 0 && Mind.categoryName,
          });
        });
      } else if (time.mainCategoryId === "60b784aeba42b00b94fbf98c") {
        time.categories.forEach((Body) => {
          Bodyoption.push({
            value: this.state.testObject.length > 0 && Body.categoryName,
            label: this.state.testObject.length > 0 && Body.categoryName,
          });
        });
      } else {
        time.categories.forEach((Career) => {
          Careeroptions.push({
            value: this.state.testObject.length > 0 && Career.categoryName,
            label: this.state.testObject.length > 0 && Career.categoryName,
          });
        });
      }
    });

    let pagesCount = Math.ceil(this.state.pagesCount);
    let PagesArray = [];
    for (let page = 1; page <= pagesCount; page++) {
      PagesArray.push(page);
    }
    const requestASession = (gurusName) => {
      this.setState({
        requestASession: true,
        message: `I would like to book a session with ${gurusName}, but she is fully booked.Can we talk about availabilities?`,
      });
    };

    return (
      <div
        className={
          this.state.toggleFilterPage ||
          this.state.toggleDropdownMenu ||
          this.state.toggleHamburgerMenu
            ? "filterPageActive homePage"
            : "homePage"
        }
      >
        {this.state.isBannerLoading ? <FullScreenLoading /> : <></>}
        <>
          {this.state.fastBookingDetails && (
            <BookingModal
              bookPages={"confirmSessionDetails"}
              exact={this.state.fastBookingDetails.exact}
              isPackage={this.props.location.state.isPackage}
              bookingPopup={this.state.isBookingModalOpened}
              toggleBookingPages={() => {
                this.setState({
                  bookingPopup: false,
                  fastBookingDetails: null,
                });
              }}
              firstName={this.state.fastBookingDetails.fullName.split(" ")[0]}
              lastName={this.state.fastBookingDetails.fullName.split(" ")[1]}
              specialization={this.state.fastBookingDetails.specialization}
              id={this.state.fastBookingDetails.id}
              price={this.state.fastBookingDetails.price}
              email={this.state.fastBookingDetails.email}
              pricePerHour={this.state.fastBookingDetails.price}
              unit={this.state.fastBookingDetails.unit}
              rate={this.state.fastBookingDetails.rate}
              image={this.state.fastBookingDetails.image}
              prefix={this.state.fastBookingDetails.name}
              exactDate={this.state.fastBookingDetails.exactDate}
              exactTime={this.state.fastBookingDetails.exactTime}
              exactPeriod={this.state.fastBookingDetails.exactPeriod}
              perDayDiscount={this.state.fastBookingDetails.perDayDiscount}
              packages={this.props.location.state.packages}
            />
          )}
          {this.state.paymentMsgModal && (
            <BookingModalStatus
              paymentMsgModal={this.state.paymentMsgModal}
              togglePaymentMsgModal={this.togglePaymentMsgModal}
              paymentStatus={this.state.paymentStatus}
            />
          )}
          <span className="navbarContainer">
            <NavBarLg
              active="Doctors"
              toggleFilterPageState={this.state.toggleFilterPage}
              toggleFilterPage={this.toggleFilterPage}
              toggleDropdownMenuState={this.state.toggleDropdownMenu}
              toggleDropdownMenu={this.toggleDropdownMenu}
              searchedCoachList={this.searchedCoachList}
              searchedPagesCount={this.filteredPagesCount}
              searchBar={true}
              fixedNavbar={this.state.calendarPos < 0 ? false : true}
              getSearchVal={(val) => {
                this.setState({ searchVal: val });
              }}
            />
          </span>
          <NavBarSm
            toggleFilterPageState={this.state.toggleFilterPage}
            toggleFilterPage={this.toggleFilterPage}
            toggleHamburgerMenu={this.toggleHamburgerMenu}
            toggleHamburgerMenuState={this.state.toggleHamburgerMenu}
            searchedCoachList={this.searchedCoachList}
            searchedPagesCount={this.filteredPagesCount}
            getSearchVal={(val) => {
              this.setState({ searchVal: val });
            }}
          />
          {/*this.state.toggleFilterPage && (
					<FilterComponent
						filteredprops={this.filteredprops}
						filteredPagesCount={this.filteredPagesCount}
						toggleFilterPage={this.toggleFilterPage}
						filteredCoaches={this.filteredCoaches}
					/>
				)*/}
          <FilterModal
            filterState={this.state.toggleFilterPage}
            filteredprops={this.filteredprops}
            filteredPagesCount={this.filteredPagesCount}
            toggleFilterPage={this.toggleFilterPage}
            filteredCoaches={this.filteredCoaches}
          />
          {/*this.state.toggleDropdownMenu && (
					<DropDownMenu toggleDropdownMenu={this.toggleDropdownMenu} />
				)*/}
          <DropDownModal
            toggleDropdownMenu={this.toggleDropdownMenu}
            dropDownState={this.state.toggleDropdownMenu}
          />
          {this.state.toggleHamburgerMenu && (
            <HamburgerList
              toggleHamburgerMenu={this.toggleHamburgerMenu}
              toggleHamburgerMenuState={this.state.toggleHamburgerMenu}
            />
          )}
          <div className="landingPage">
            <div className="landingText">
              <div className="textContainer">
                <div>{this.state.WordInTopOfBanner}</div>
                <img
                  src={this.state.logo}
                  alt="Logo"
                  className="bannerLogo"
                  onLoad={() => {
                    this.setState({ isBannerLoading: false });
                  }}
                />
                <div>{this.state.WordInBottomOfBanner}</div>
              </div>
            </div>
            {/* <div className="events-section">
						<div className="avatar-container">
							<img src={avatar} alt="avatar" className="avatar" />
							<img src={watchVideo} alt="watchVideo" className="watchVideo" />
						</div>
						<div>
							<div className="content-section">
								<div className="fw-600">Mohamed Hashim</div>
								<div className="mainColor fw-500">Life coach</div>
								<div className="mt-2 content f-14">
									Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quod
									cum mollitia veritatis, dicta voluptas qui laudantium
									assumenda ratione distinctio temporibus, sit facere!
									Voluptatibus molestias tempora minus velit at odio quasi.
								</div>
							</div>
							<div className="text-center mt-2">
								<button className="primary-btn py-1">Book Now</button>
							</div>
						</div>
					</div> */}

            {this.state.testObject.length > 0 && (
              <div className="tabs">
                <div className="category-button-container">
                  <div style={{ height: "75px" }}></div>
                  <button
                    className={
                      // eslint-disable-next-line
                      this.state.mainCategoryFilterId ==
                      this.state.testObject[0].mainCategoryId
                        ? "selected category-button"
                        : "category-button"
                    }
                    value={[
                      this.state.testObject.length > 0
                        ? this.state.testObject[0].mainCategoryId
                        : "",
                    ]}
                    onClick={this.mainCategoryChange}
                  >
                    {[
                      this.state.testObject.length > 0
                        ? this.state.testObject[0].mainCategoryName
                        : "",
                    ]}
                  </button>
                  <button
                    className={
                      // eslint-disable-next-line
                      this.state.mainCategoryFilterId ==
                      this.state.testObject[1].mainCategoryId
                        ? "selected category-button"
                        : "category-button"
                    }
                    value={[
                      this.state.testObject.length > 0
                        ? this.state.testObject[1].mainCategoryId
                        : "",
                    ]}
                    onClick={this.mainCategoryChange}
                  >
                    {[
                      this.state.testObject.length > 0
                        ? this.state.testObject[1].mainCategoryName
                        : "",
                    ]}
                  </button>
                  <button
                    className={
                      // eslint-disable-next-line
                      this.state.mainCategoryFilterId ==
                      this.state.testObject[2].mainCategoryId
                        ? "selected category-button"
                        : "category-button"
                    }
                    value={[
                      this.state.testObject.length > 0
                        ? this.state.testObject[2].mainCategoryId
                        : "",
                    ]}
                    onClick={this.mainCategoryChange}
                  >
                    {[
                      this.state.testObject.length > 0
                        ? this.state.testObject[2].mainCategoryName
                        : "",
                    ]}
                  </button>
                </div>
              </div>
            )}
          </div>
          <div id="homePageCalendar"></div>
          <span
            className={this.state.calendarPos < 0 && "homePageCalendarscroll"}
          >
            {this.state.timeServer && (
              <HomePageCalendar
                getCurrentPages={this.handleCurrentDatesArr}
                handleCalendarPages={this.handleCalendarPages}
                timeServer={this.state.timeServer}
                getAllDates={this.getAllDates}
              />
            )}
          </span>

          {this.state.loadingGurus && (
            <div>
              <div className="loader"></div>
            </div>
          )}

          {!this.state.loadingGurus && (
            <>
              <div className="coachesContainer">
                {this.state.coaches.map((coach, i) => {
                  return (
                    <span key={i}>
                      <CoachCard
                        index={this.state.index}
                        allDates={this.state.allDates}
                        getBookingModalStatus={(val) => {
                          this.setState({ bookingModalStatus: val });
                        }}
                        prefix={coach.prefix}
                        id={coach._id}
                        firstName={coach.firstName}
                        email={coach.email}
                        lastName={coach.lastName}
                        coachBio={coach.coachBio}
                        pricePerHour={coach.pricePerHour}
                        unit={coach.unit}
                        rate={coach.rate}
                        image={coach.image}
                        video={coach.video}
                        specialization={coach.category}
                        subspecializations={coach.subCategory}
                        schedules={coach.schedules}
                        min={this.state.min}
                        max={this.state.max}
                        currentDatesArr={this.state.currentDatesArr}
                        schedulesNew={coach.schedulesNew}
                        perDayDiscount={coach.perDayDiscount}
                        requestASession={requestASession}
                        packages={coach.packages}
                        isFirstSessionFree={coach.isFirstSessionFree}
                      />
                    </span>
                  );
                })}
              </div>
              <div className="pagination">
                {this.state.index !== 1 && (
                  <span onClick={this.prevPage} className="arrowLeft">
                    <ArrowLeft />
                  </span>
                )}
                {PagesArray.map((pageNumber, i) => {
                  return (
                    <span
                      key={i}
                      className={
                        this.state.index === pageNumber ? "active" : ""
                      }
                      id={pageNumber}
                      onClick={this.switchPages}
                    >
                      {pageNumber}
                    </span>
                  );
                })}
                {this.state.index !== PagesArray.length && (
                  <span onClick={this.nextPage} className="arrowRight">
                    <ArrowRight />
                  </span>
                )}
              </div>
            </>
          )}
          <Footer />
          <FloatingBar
            active="home"
            bookingPopup={this.state.bookingModalStatus}
            requestASession={this.state.requestASession}
            message={this.state.message}
          />
        </>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    book: state.book,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    BookAction: (book) => dispatch(BookAction(book)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
