import React, { Component } from "react";
import ChevronRight from "../../Components/svgLogos/HomePageFigures/ChevronRight";
import ChevronLeft from "../../Components/svgLogos/HomePageFigures/ChevronLeft";
import moment from "moment";
import "./HomePageCalendar.css";
export class HomePageCalendar extends Component {
	state = {
		min: 0,
		max: 5,
		Dates: [],
		fullDates: [],
		timeZone: localStorage.getItem("timeZone"),
	};
	componentDidMount() {
		var currentDate = this.props.timeServer; // Now
		var Dates = [];

		for (let i = 0; i < 90; i++) {
			Dates.push({
				fullDate: moment(currentDate).add(i, "days").format("YYYY-MM-DD"),
			});
		}
		let currentDatesArr = [];
		for (let i = this.state.min; i < this.state.max; i++) {
			currentDatesArr.push(Dates[i].fullDate);
		}
		this.props.getCurrentPages(currentDatesArr);
		this.props.getAllDates(Dates);
		this.setState({ Dates });
	}
	render(props) {
		let currentDatesArr = [];
		return (
			<div className="HomePageCalendar scroll">
				<div className="calendarHeaderContainer  container-fluid">
					<div className="divider"></div>
					<div className="row d-flex align-items-center">
						<div className="calendarHeaderTitle  col-5">
							<div className="row">
								<div
									className="col-1 CalendarControls"
									onClick={() => {
										if (!(this.state.min === 0)) {
											this.setState(
												{
													min: this.state.min - 5,
													max: this.state.max - 5,
												},
												() => {
													for (
														let i = this.state.min;
														i < this.state.max;
														i++
													) {
														currentDatesArr.push(this.state.Dates[i].fullDate);
													}
													this.props.getCurrentPages(currentDatesArr);
													this.props.handleCalendarPages(this.state.min);
												}
											);
										}
									}}>
									<ChevronLeft />
								</div>
								{this.state.Dates.map((date, i) => {
									return (
										i >= this.state.min &&
										i < this.state.max && (
											<div key={i} className="col-2">
												<div>{moment(date.fullDate).format("dd")}</div>
												<span className="f-12">
													{moment(date.fullDate).format("D")}{" "}
													{moment(date.fullDate).format("MMM")}
												</span>
											</div>
										)
									);
								})}
								<div
									className="col-1 CalendarControls"
									onClick={() => {
										if (!(this.state.max > 85)) {
											this.setState(
												{
													min: this.state.min + 5,
													max: this.state.max + 5,
												},
												() => {
													this.props.handleCalendarPages(this.state.min);
													for (
														let i = this.state.min;
														i < this.state.max;
														i++
													) {
														currentDatesArr.push(this.state.Dates[i].fullDate);
													}
													this.props.getCurrentPages(currentDatesArr);
													this.props.handleCalendarPages(this.state.min);
												}
											);
										}
									}}>
									<ChevronRight />
								</div>
							</div>
						</div>
					</div>
					<div className="divider"></div>
				</div>
			</div>
		);
	}
}

export default HomePageCalendar;
