import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
function StripeContainer({
  coupon,
  paymentData,
  setRedirectToPay,
  onClick,
  checkValidEmail,
  mode,
  back,
  isPackage,
  isEvent,
}) {
  //Testing
  // const publickey =
  // 	"pk_test_51H1TK4D1UhlCEqvk9RpUeuP6nCp2UK1X4X3LDZAB2STFRE4FmidcYxJ9w42qHg2uWeJrugIM07TVemCfuzmQexd400Nnr6yy7a";
  // const StripePromice = loadStripe(publickey);

  //Live
  // const publishKey =
  // 	"pk_live_51H1TK4D1UhlCEqvkYnk4j6qBf3jaKstuoo0uStF8fpjkb5THEZwuZjzanDSNC7buf5MDd6uw5npNium4WCFZsd9o00w1R35eU4";
  // const StripePromice = loadStripe(publishKey);

  const key = process.env.REACT_APP_STRIPE_KEY;
  const StripePromice = loadStripe(key);

  return (
    <Elements stripe={StripePromice}>
      <PaymentForm
        paymentData={paymentData}
        setRedirectToPay={setRedirectToPay}
        onClick={onClick}
        coupon={coupon}
        checkValidEmail={checkValidEmail}
        mode={mode}
        back={back}
        isPackage={isPackage}
        isEvent={isEvent}
      />
    </Elements>
  );
}

export default StripeContainer;
