import React, { Component } from "react";
import { baseURL } from "../../App";
import { Link, withRouter, Redirect } from "react-router-dom";
import VideoModal from "../../Components/VideoModal/VideoModal";
import BookingModal from "../../Components/BookingModal/BookingModal";
import { connect } from "react-redux";
import PlayIcon from "../../Components/svgLogos/HomePageFigures/PlayIcon";
import FilledStar from "../../Components/svgLogos/FilledStar";
import EmptyStar from "../../Components/svgLogos/EmptyStar";
import { BookAction } from "../../Store/Actions/BookAction";
import { Modal } from "react-bootstrap";
import moment from "moment";
import Appointments from "../Appointments/Appointments";
import watchVideo from "../../Assets/watch-video.svg";
import "./CoachCard.css";

// const exchangeCurrency = localStorage.getItem("exchangeCurrency");
// const exchangeRate = localStorage.getItem("exchangeRate");

export class CoachCard extends Component {
  state = {
    notAuth: false,
    videoPopup: false,
    bookingPopup: false,
    exactDate: null,
    exactTime: "",
    exactPeriod: "",
    disable: false,
    redirectStateSigniN: false,
    sameAccountModal: false,
    timeZone: window.localStorage.getItem("timeZone"),
    exact: null,
    hasNoSessions: true,
    nextSessionAv: null,
    immediateNextSessionAv: null,
    isPackage: null,
    exchangeCurrency: window.localStorage.getItem("exchangeCurrency"),
    exchangeRate: window.localStorage.getItem("exchangeRate"),
  };

  toggleVideoPopup = () => {
    this.setState({ videoPopup: !this.state.videoPopup });
  };
  toggleBookingPages = () => {
    if (this.props.auth.authData === undefined) {
      this.setState({ notAuth: true });
    } else {
      this.setState(
        {
          bookingPopup: !this.state.bookingPopup,
        },
        () => {
          this.props.getBookingModalStatus(this.state.bookingPopup);
        }
      );
    }
  };

  getNextSession = () => {
    const upcomingDates = this.props.schedulesNew.filter(
      (schedule) =>
        schedule.times.length > 0 &&
        moment(this.props.currentDatesArr[0]).valueOf() <
          moment(schedule.fullDate).valueOf()
    );

    const immediateUpcomingDates = this.props.schedulesNew.filter(
      (schedule) =>
        schedule.times.length &&
        moment(this.props.currentDatesArr[0]).valueOf() <=
          moment(schedule.fullDate).valueOf()
    );
    if (upcomingDates.length && upcomingDates[0].times.length) {
      this.setState({
        nextSessionAv: upcomingDates[0].times[0],
        immediateNextSessionAv: immediateUpcomingDates[0].times[0],
      });
    } else {
      this.setState({ nextSessionAv: null });
    }
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.max !== this.props.max ||
      prevProps.currentDatesArr !== this.props.currentDatesArr ||
      prevProps.email !== this.props.email
    ) {
      this.getNextSession();
      this.setState({ bookingPopup: false });
    }
  }
  componentDidMount() {
    this.getNextSession();
  }

  render(props) {
    let bioShortText = this.props.coachBio;
    if (bioShortText.length > 200) {
      bioShortText = bioShortText.slice(0, 200) + "...";
    }
    let hasSessions = false;
    let selectedDates = [[], [], [], [], []];
    const modifiedSchedules = [];
    this.props.schedulesNew.forEach((times) => {
      times.times.forEach((time) => {
        modifiedSchedules.push(time);
      });
    });
    // this gives an object with dates as keys
    const groupedSchedules = modifiedSchedules.reduce((groups, game) => {
      const date = game.startDateTime.split(",")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(game);
      return groups;
    }, {});

    const closeAndOpenBooking = () => {
      this.setState({
        videoPopup: false,
        bookingPopup: true,
        exact: null,
      });
    };

    const finalSchedules = Object.keys(groupedSchedules).map((date) => {
      return {
        fullDate: date,
        times: groupedSchedules[date],
      };
    });

    finalSchedules.forEach((schedule, scheduleIndex) => {
      for (let j = 0; j < 5; j++) {
        if (
          moment(this.props.currentDatesArr[j]).format("YYYY-MM-DD") ===
          moment(schedule.fullDate).format("YYYY-MM-DD")
        ) {
          selectedDates[j] = schedule.times;
          hasSessions = true;
        }
      }
    });

    let filledStar = [];
    let emptyStar = [];
    let rate = this.props.rate ? this.props.rate : 5;
    filledStar.length = Math.ceil(rate);
    emptyStar.length = 5 - Math.ceil(rate);
    filledStar.fill("filled");
    emptyStar.fill("empty");

    const priceBeforeDiscount =
      this.props.pricePerHour === 0
        ? "Free"
        : this.state.exchangeCurrency +
          " " +
          Math.ceil(
            Math.ceil(this.state.exchangeRate * this.props.pricePerHour)
          ) +
          "/hr";

    var priceAfterDiscount = null;

    if (
      this.props.perDayDiscount &&
      this.props.auth.authData &&
      this.props.auth.authData.user.freeSessionCount === 0
    ) {
      priceAfterDiscount =
        this.props.pricePerHour === 0
          ? "Free"
          : this.state.exchangeCurrency +
            " " +
            Math.ceil(
              this.props.pricePerHour *
                ((100 - this.props.perDayDiscount) / 100) *
                this.state.exchangeRate
            ) +
            "/hr";

      if (priceAfterDiscount.startsWith("0")) {
        priceAfterDiscount = "Free";
      }
    }
    if (this.state.notAuth) this.props.history.push("signin");
    if (this.state.redirectStateSigniN) {
      return (
        <Redirect
          to={{
            pathname: "/signin",
            state: {
              message: "Please signin/signup to start book sessions",
              isPackage: this.state.isPackage,
              sessionInfo: {
                fullName: this.props.firstName + " " + this.props.lastName,
                specialization: this.props.specialization,
                id: this.props.id,
                price: this.props.pricePerHour,
                sessionDateTime:
                  this.state.redirectStateSigniN === true
                    ? null
                    : this.state.redirectStateSigniN,
                image: this.props.image,
                email: this.props.email,
                rate: this.props.rate,
                exact: this.state.redirectStateSigniN,
                unit: this.state.exchangeCurrency,
                prefix: this.props.prefix[0].name,
                exactDate: this.state.redirectStateSigniN.startDateTime,
                exactTime: this.state.redirectStateSigniN.startDateTime,
                exactPeriod: this.state.redirectStateSigniN.period,
                perDayDiscount: this.props.perDayDiscount,
                packages: this.props.packages,
              },
              packages: this.props.packages,
            },
          }}
        />
      );
    }

    return (
      <div className="coachCard">
        {/*Video and Booking Modal*/}

        {this.state.videoPopup && (
          <VideoModal
            toggleBookingPages={this.toggleBookingPages}
            videoPopup={this.state.videoPopup}
            toggleVideoPopup={this.toggleVideoPopup}
            video={this.props.video}
            firstName={this.props.firstName}
            lastName={this.props.lastName}
            specialization={this.props.specialization}
            id={this.props.id}
            pricePerHour={this.props.pricePerHour}
            unit={this.props.unit}
            rate={this.props.rate}
            image={this.props.image}
            prefix={this.props.prefix[0].name}
            exactDate={this.state.exactDate}
            exactTime={this.state.exactTime}
            exactPeriod={this.state.exactPeriod}
            closeAndOpenBooking={closeAndOpenBooking}
            sameAccountModal={() => {
              this.setState({ sameAccountModal: true, videoPopup: false });
            }}
            redirectStateSigniN={() => {
              this.setState({ redirectStateSigniN: true });
            }}
          />
        )}
        {this.state.bookingPopup && (
          <BookingModal
            exact={this.state.exact}
            bookingPopup={this.state.bookingPopup}
            toggleBookingPages={this.toggleBookingPages}
            firstName={this.props.firstName}
            lastName={this.props.lastName}
            specialization={this.props.specialization}
            id={this.props.id}
            isPackage={this.state.isPackage}
            email={this.props.email}
            pricePerHour={this.props.pricePerHour}
            unit={this.props.unit}
            rate={this.props.rate}
            image={this.props.image}
            prefix={this.props.prefix[0].name}
            exactDate={this.state.exactDate}
            exactTime={this.state.exactTime}
            exactPeriod={this.state.exactPeriod}
            perDayDiscount={this.props.perDayDiscount}
            packages={this.props.packages}
          />
        )}
        {/*Video and Booking Modal*/}

        <div className="container-fluid">
          <div className="row">
            <div className="col-4 col-md-2 text-center">
              <div className="imgContainer">
                <div className="img-container">
                  <Link to={"/gurus/" + this.props.id}>
                    <img
                      src={baseURL + "/" + this.props.image}
                      className="avatar"
                      alt="avatar"
                    />
                  </Link>
                  {this.props.video && (
                    <img
                      src={watchVideo}
                      alt="watchVideo"
                      className="watchVideoImg cursorPointer"
                      onClick={this.toggleVideoPopup}
                    />
                  )}
                </div>
              </div>
              {/* {this.props.video && (
								<div className="watchVideoContainer lg">
									<button
										onClick={this.toggleVideoPopup}
										className="watchVideoBtn lg btn">
										watch video
										<span>
											<PlayIcon />
										</span>
									</button>
								</div>
							)} */}

              {this.props.auth.authData ? (
                <button
                  onClick={() => {
                    if (
                      this.props.auth.authData &&
                      this.props.auth.authData.userType === 1
                    ) {
                      this.setState({ exact: null, isPackage: false }, () =>
                        this.toggleBookingPages()
                      );
                    } else if (
                      this.props.auth.authData &&
                      this.props.auth.authData.userType === 2
                    ) {
                      this.setState({
                        sameAccountModal: true,
                        isPackage: false,
                      });
                    } else {
                      this.setState({
                        redirectStateSigniN: true,
                        isPackage: false,
                      });
                    }
                  }}
                  className="bookCoachBtn lg btn"
                >
                  Book Now
                </button>
              ) : (
                <button
                  onClick={() => {
                    if (
                      this.props.auth.authData &&
                      this.props.auth.authData.userType === 1
                    ) {
                      this.setState({ exact: null, isPackage: false }, () =>
                        this.toggleBookingPages()
                      );
                    } else if (
                      this.props.auth.authData &&
                      this.props.auth.authData.userType === 2
                    ) {
                      this.setState({
                        sameAccountModal: true,
                        isPackage: false,
                      });
                    } else {
                      this.setState({
                        redirectStateSigniN: true,
                        isPackage: false,
                      });
                    }
                  }}
                  className="bookCoachBtn lg btn"
                >
                  Book Now
                </button>
              )}
            </div>
            <div className="col-8 col-md-5 data">
              <Link to={"/gurus/" + this.props.id}>
                <div className="fullName">
                  {this.props.prefix.length > 0 &&
                    this.props.prefix[0].name +
                      " " +
                      this.props.firstName +
                      " " +
                      this.props.lastName}
                  {/* 
								<div>
									<SubscribeIcon />
								</div>
							*/}
                </div>
              </Link>

              <div className="specialization">
                {this.props.specialization[0].categoryName}
              </div>
              <div className="coachRate" style={{ display: "none" }}>
                {filledStar.map((rate, i) => {
                  return (
                    <div key={i} className="filledStar">
                      <FilledStar />
                    </div>
                  );
                })}
                {emptyStar.map((emptyStar, i) => {
                  return (
                    <div key={i} className="emptyStar">
                      <EmptyStar />
                    </div>
                  );
                })}
              </div>
              {this.props.video && (
                <div className="watchVideoContainer sm">
                  <button
                    onClick={this.toggleVideoPopup}
                    className="watchVideoBtn  btn"
                  >
                    watch video
                    <span>
                      <PlayIcon />
                    </span>
                  </button>
                </div>
              )}

              <div
                className="bio lg"
                dangerouslySetInnerHTML={{ __html: bioShortText }}
              />
              {this.props.subspecializations.map((subspecialization, i) => {
                return (
                  <span key={i} className="keyword lg">
                    {subspecialization.subCategoryName}
                  </span>
                );
              })}

              <div className="pricePerHour lg">
                {this.props.isFirstSessionFree && (
                  <div>First Discovery Session Free</div>
                )}
                <span
                  className={
                    priceAfterDiscount ? "priceBeforeDiscount mr-2" : ""
                  }
                >
                  {priceBeforeDiscount}
                </span>
                <span>{priceAfterDiscount}</span>
                {this.props.packages?.length > 0 && (
                  <span className=" f-18 d-block">
                    <span
                      className="cursorPointer"
                      onClick={() => {
                        if (
                          this.props.auth.authData &&
                          this.props.auth.authData.userType === 1
                        ) {
                          this.setState({ exact: null, isPackage: true }, () =>
                            this.toggleBookingPages()
                          );
                        } else if (
                          this.props.auth.authData &&
                          this.props.auth.authData.userType === 2
                        ) {
                          this.setState({
                            isPackage: true,
                            sameAccountModal: true,
                          });
                        } else {
                          this.setState({
                            isPackage: true,
                            redirectStateSigniN: true,
                          });
                        }
                      }}
                    >
                      buy a package for{" "}
                      {Math.ceil(
                        this.state.exchangeRate * this.props.packages[0].price
                      )}
                      {this.state.exchangeCurrency}/
                      {this.props.packages[0].sessionCount} Sessions
                    </span>
                  </span>
                )}
              </div>
            </div>

            <div className="flex-1">
              <Appointments
                currentDatesArr={this.props.currentDatesArr}
                selectedDates={selectedDates}
                hasSessions={hasSessions}
                nextSessionAv={this.state.nextSessionAv}
                firstName={this.props.firstName}
                lastName={this.props.lastName}
                requestASession={this.props.requestASession}
                toggleBookingPages={this.toggleBookingPages}
                updateSameAccountModal={(value) => {
                  this.setState({ sameAccountModal: value, isPackage: false });
                }}
                updateRedirectStateSigniN={(value) => {
                  this.setState({
                    redirectStateSigniN: value,
                    isPackage: false,
                  });
                }}
                updateExact={(value) => {
                  this.setState({ exact: value, isPackage: false }, () => {
                    this.toggleBookingPages();
                  });
                }}
              />
            </div>
            <div
              className="bio sm col-12"
              dangerouslySetInnerHTML={{ __html: bioShortText }}
            />

            <div className="col-md-8 col-xs-12">
              {this.props.subspecializations.map((subspecialization, i) => {
                return (
                  <span key={i} className="keyword sm">
                    {subspecialization.subCategoryName}
                  </span>
                );
              })}
              {
                <div className="d-flex justify-content-between flex-1 mt-2">
                  <div className="d-md-none">
                    {this.state.nextSessionAv ? (
                      <div>
                        <div>Next available session on:</div>
                        <div
                          className="nextAvSession"
                          onClick={() => {
                            if (
                              this.props.auth.authData &&
                              this.props.auth.authData.userType === 1
                            ) {
                              this.setState(
                                {
                                  exact: this.state.immediateNextSessionAv,
                                  isPackage: false,
                                },
                                () => this.toggleBookingPages()
                              );
                            } else if (
                              this.props.auth.authData &&
                              this.props.auth.authData.userType === 2
                            ) {
                              this.setState({
                                sameAccountModal: true,
                                isPackage: false,
                              });
                            } else {
                              this.setState({
                                redirectStateSigniN:
                                  this.state.immediateNextSessionAv,
                                isPackage: false,
                              });
                            }
                          }}
                        >
                          {moment(
                            this.state.immediateNextSessionAv.startDateTime
                          ).format("LLL")}
                        </div>
                      </div>
                    ) : (
                      <>
                        <>No Available Sessions</>
                        {(!this.props.auth.authData ||
                          this.props.auth.authData?.userType === 1) && (
                          <div
                            className="nextAvSession"
                            onClick={() => {
                              this.props.requestASession(
                                `${this.props.firstName} ${this.props.lastName}`
                              );
                            }}
                          >
                            Request a session
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <span className="ButtonsContainer sm">
                    <div className="pricePerHour sm ">
                      {this.props.isFirstSessionFree && (
                        <div>First Discoverable Session Free</div>
                      )}
                      <span
                        className={
                          priceAfterDiscount ? "priceBeforeDiscount mr-2" : ""
                        }
                      >
                        {priceBeforeDiscount}
                      </span>
                      <span>{priceAfterDiscount}</span>
                    </div>

                    <button
                      onClick={() => {
                        if (
                          this.props.auth.authData &&
                          this.props.auth.authData.userType === 1
                        ) {
                          this.setState({ exact: null, isPackage: false }, () =>
                            this.toggleBookingPages()
                          );
                        } else if (
                          this.props.auth.authData &&
                          this.props.auth.authData.userType === 2
                        ) {
                          this.setState({
                            sameAccountModal: true,
                            isPackage: false,
                          });
                        } else {
                          this.setState({
                            redirectStateSigniN: true,
                            isPackage: false,
                          });
                        }
                      }}
                      className="bookCoachBtn  btn"
                    >
                      Book Now
                    </button>
                    <div>
                      {this.props.packages?.length > 0 && (
                        <span className="mainColor f-18 d-block">
                          <span
                            className="cursorPointer"
                            onClick={() => {
                              if (
                                this.props.auth.authData &&
                                this.props.auth.authData.userType === 1
                              ) {
                                this.setState(
                                  { exact: null, isPackage: true },
                                  () => this.toggleBookingPages()
                                );
                              } else if (
                                this.props.auth.authData &&
                                this.props.auth.authData.userType === 2
                              ) {
                                this.setState({
                                  isPackage: true,
                                  sameAccountModal: true,
                                });
                              } else {
                                this.setState({
                                  isPackage: true,
                                  redirectStateSigniN: true,
                                });
                              }
                            }}
                          >
                            buy a package for{" "}
                            {Math.ceil(
                              this.state.exchangeRate *
                                this.props.packages[0].price
                            )}
                            {this.state.exchangeCurrency}/
                            {this.props.packages[0].sessionCount} Sessions
                          </span>
                        </span>
                      )}
                    </div>
                  </span>
                </div>
              }
            </div>
          </div>

          <div className="divider"></div>
        </div>

        {this.state.sameAccountModal && (
          <Modal
            show={this.state.sameAccountModal}
            onHide={() => {
              this.setState({
                sameAccountModal: false,
              });
            }}
          >
            <Modal.Body className="text-center">
              You must signup as a client with a different Email to book a
              session
            </Modal.Body>
          </Modal>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    book: state.book,
    currency: state.currencyData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    BookAction: (book) => dispatch(BookAction(book)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CoachCard));
