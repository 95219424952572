import React, { Component } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import DatePicker from "react-mobile-datepicker";
import CancelSessionImg from "../../Assets/ConfirmCancelSession.svg";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import firebase from "firebase/app";
import {
	baseURL,
	exchangeCurrency,
	exchangeRate,
	isSummerTime,
} from "../../App";
import "firebase/firestore";
import "./ViewSessionModal.css";
import { Button } from "antd";
export class ViewSessionModal extends Component {
	constructor(props) {
		super(props);
		this.lastMsgRef = React.createRef();
		this.state = {
			clientComment: "",
			mentorComment: "",
			isOpen: true,
			isClicked: false,
			theme: "ios",
			dayName: null,
			prevDayName: null,
			nextDayName: null,
			timeSlot: "",
			period: "",
			timeZone: localStorage.getItem("timeZone"),
			sessionDetailsPage: "viewSessionDetails",
			availableTimes: [{ time: "10:00 - 11:00", period: "15", booked: false }],
			discount: 0,
			price: 0,
			unit: exchangeCurrency,
			msg: "",
			msgsContainer: [],
			isLoading: false,
		};
	}
	popupPages = (e) => {
		this.setState({ sessionDetailsPage: e.target.id });
	};

	handleCancelSession = () => {
		this.setState({ isLoading: true }, () => {
			axios({
				method: "post",
				url: baseURL + "/account/DeleteSessionNew",
				data: {
					sessionId: this.props.session._id,
					userType: this.props.auth.authData.userType,
					userTimeZone: this.state.timeZone,
					currency: exchangeRate,
				},
			})
				.then((res) => {
					this.setState({ isLoading: false });
					if (res.status === 200) {
						this.setState({
							sessionDetailsPage: "ConfirmCancelSession",
							price: res.data.data.price,
							unit: exchangeCurrency,
							discount: parseInt(res.data.discount) / 100,
						});
						axios({
							method: "post",
							url: baseURL + "/account/NotifyChangeSchedule",
						}).then((res) => {
							window.location.reload();
						});
					}
				})
				.catch((error) => {
					this.setState({ isLoading: false });
				});
		});
	};
	handleUpdateMsgs = () => {
		axios({
			method: "post",
			url: baseURL + "/user/AddSessionComment",
			data: {
				userType: this.props.auth.authData.userType,
				comments: [
					{
						comment:
							this.props.auth.authData.userType === 1
								? this.state.clientComment
								: this.state.mentorComment,
						fullDate: new Date(),
					},
				],
				sessionId: this.props.session._id,
			},
		}).then((res) => {
			this.props.handlePopup(false);
		});
	};
	changeHandler = (e) => {
		this.setState({ [e.target.id]: e.target.value });
	};
	getMessages = () => {
		const roomId = this.props.session._id;
		console.log("roomId",roomId)
		const roomsDocRef = firebase
			.firestore()
			.collection("sessionRooms")
			.doc(roomId);
		roomsDocRef
			.get()
			.then((querySnapshot) => {
				
				if (querySnapshot.exists) {
					console.log("querySnapshot",querySnapshot)
					const data = querySnapshot.data();
					const hasUnreadMsg = data?.messages?.some(
						(message) =>
							message.isRead === false &&
							message.senderId !== this?.props?.auth?.authData?.user?._id
					);
					this.props.getHasUnreadMsg(hasUnreadMsg);
					this.setState({
						msgsContainer: data.messages,
					});
					//console.log('msgsContainer',this.state.msgsContainer)
				} else {
					console.log("No such document!");
				}
			})
			.catch((error) => {
				console.log("Error getting documents:", error);
			});
	};
	// fetchMessages = () => {
	// 	const query = db.collection('sessionRooms').orderBy('createdAt');
	// 	query.onSnapshot((snapshot) => {
	// 	  snapshot.docChanges().forEach((change) => {
	// 		const messageObj = {}
	// 		messageObj.data = change.doc.data()
	// 		messageObj.id = change.doc.id
	// 		this.setState({
	// 		  ...this.state,
	// 		  messages: [messageObj, ...this.state.messages]
	// 		}) })
	// 	})
	// }
	componentDidMount() {
		this.getMessages();
		var weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
		var dayNum = new Date().getDay();
		var nextDayNum = new Date().getDay() + 1;
		var prevDayNum = new Date().getDay() - 1;
		if (prevDayNum === -1) {
			prevDayNum = 6;
		}
		if (nextDayNum === 7) {
			nextDayNum = 0;
		}
		axios({
			method: "get",
			url: baseURL + "/user/getSessionComments?Id=" + this.props.session._id,
		}).then((res) => {
			res.data.sessionComments.length > 0 &&
				this.setState({
					mentorComment:
						res.data.sessionComments[0].coachComment.length > 0
							? res.data.sessionComments[0].coachComment[
									res.data.sessionComments[0].coachComment.length - 1
							  ].comment
							: "",

					clientComment:
						res.data.sessionComments[0].userComment.length > 0
							? res.data.sessionComments[0].userComment[
									res.data.sessionComments[0].userComment.length - 1
							  ].comment
							: "",
				});
		});
		this.setState({
			prevDayName: weekday[prevDayNum],
			dayName: weekday[dayNum],
			nextDayName: weekday[nextDayNum],
			time: new Date(),
		});
	}
	componentWillUnmount() {
		const sessionCountdownNode = document.querySelector(".sessionCountdown");
		if (sessionCountdownNode) {
			sessionCountdownNode.classList.remove("d-none");
		}
	}
	componentDidUpdate(prevProps) {
		let upcomingSessionNode = document.querySelector(".sessionCountdown");
		if (upcomingSessionNode) {
			if (prevProps.sessionDetailsPopup !== this.props.sessionDetailsPopup) {
				if (this.props.sessionDetailsPopup)
					upcomingSessionNode.classList.add("d-none");
				else upcomingSessionNode.classList.remove("d-none");
			}
		}
	}
	render(props) {
		//this.getMessages();
		const sessionCountdownNode = document.querySelector(".sessionCountdown");
		if (sessionCountdownNode) {
			sessionCountdownNode.classList.add("d-none");
		}
		const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
		const monthMap = {
			1: "Jan",
			2: "Feb",
			3: "Mar",
			4: "Apr",
			5: "May",
			6: "Jun",
			7: "Jul",
			8: "Aug",
			9: "Sep",
			10: "Oct",
			11: "Nov",
			12: "Dec",
		};

		const fullDayNames = [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		];
		const fullMonthNames = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];

		const dateConfig = {
			date: {
				format: "DD",
				caption: "Day",
				step: 1,
			},
			month: {
				format: (value) => monthMap[value.getMonth() + 1],
				caption: "Month",
				step: 1,
			},
		};

		const handleTimeSlot = (e) => {
			this.setState({
				timeSlot: e.target.id.substr(0, 13),
				period: e.target.id.substr(13),
			});
		};

		const handleChange = (time) => {
			var myDate;
			this.setState({ time }, () => {
				myDate = new Date(this.state.time);
				var dayNum = myDate.getDay();
				var nextDayNum = myDate.getDay() + 1;
				var prevDayNum = myDate.getDay() - 1;
				if (prevDayNum === -1) {
					prevDayNum = 6;
				}
				if (nextDayNum === 7) {
					nextDayNum = 0;
				}

				this.setState({
					prevDayName: weekday[prevDayNum],
					dayName: weekday[dayNum],
					nextDayName: weekday[nextDayNum],
				});
			});
		};

		var minDate = new Date();
		var maxDate = new Date();
		minDate.setDate(minDate.getDate());
		maxDate.setDate(maxDate.getDate() + 30);
		minDate.setDate(minDate.getDate() - 1);
		maxDate.setDate(maxDate.getDate() + 30);

		const handleSendMessage = async () => {
			console.log("handleSendMessage")
			const roomId = this.props.session._id;
			const roomsDocRef = firebase
				.firestore()
				.collection("sessionRooms")
				.doc(roomId);
            console.log("this.state.msg",this.state.msg)
			if (this.state.msg.length > 0) {
				const messages = [...this.state.msgsContainer];
				messages.push({
					senderId: this.props.auth.authData.user._id,
					senderInitials: (
						this.props.auth.authData.user.firstName[0] +
						this.props.auth.authData.user.lastName[0]
					).toUpperCase(),
					message: this.state.msg,
					isRead: false,
				});
				console.log("messages",messages)
				this.setState({
					msgsContainer: messages,
				});
				roomsDocRef.set({
					messages,
				});

				this.setState({ msg: "" }, () => {
					if(this.lastMsgRef.current){
					this.lastMsgRef.current.scrollIntoView({
						behavior: "smooth",
						block: "center",
						inline: "start",
					});
				}
				});
			}
		};
		return (
			<Modal
				show={this.props.sessionDetailsPopup}
				onHide={this.props.handlePopup}
				size="lg"
				id="sessionDetails"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						{this.state.sessionDetailsPage === "viewSessionDetails" && (
							<div className="viewSessionDetails">
								{/* View */}
								<div>Session Details</div>
							</div>
						)}
						{this.state.sessionDetailsPage === "cancelSession" && (
							<div className="cancelSession">
								Confirm
								<div>Session Cancellation</div>
							</div>
						)}

						{this.state.sessionDetailsPage === "modifySession" && (
							<div className="modifySession">
								Modify
								<div>Session Details</div>
							</div>
						)}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.state.sessionDetailsPage === "viewSessionDetails" &&
						this.props.session &&
						this.props.sessionType && (
							<div className="viewSessionDetails">
								<div className="container-fluid">
									<div className="row">
										<div className="col-lg-6 col-12 ">
											<div className="PersonInfo">
												{this.props.sessionType === "client" ? (
													<img
														className="initials"
														src={baseURL + "/" + this.props.session.coachImage}
														alt={
															baseURL + "/" + this.props.session.coachFullName
														}
													/>
												) : this.props.session.clientImage ? (
													<img
														className="initials"
														src={baseURL + "/" + this.props.session.clientImage}
														alt={
															baseURL + "/" + this.props.session.coachFullName
														}
													/>
												) : (
													<div className="initials">
														{this.props.session.anonymous
															? "AN"
															: this.props.session?.clientFullName?.split(" ")
																	.length === 2
															? this.props.session?.clientFullName
																	?.split(" ")[0][0]
																	?.toUpperCase() +
															  this.props.session?.clientFullName
																	?.split(" ")[1][0]
																	?.toUpperCase()
															: this.props.session?.clientFullName
																	?.split(" ")[0][0]
																	?.toUpperCase()}
													</div>
												)}

												<span className="info">
													<div
														className={
															this.props.sessionType === "coach"
																? "name client"
																: "name"
														}>
														{this.props.sessionType === "client"
															? this.props.session.prefixName +
															  " " +
															  this.props.session.coachFullName
															: this.props.session.anonymous
															? "Anonymous"
															: this.props.session.clientFullName}
													</div>
													{this.props.sessionType === "client" && (
														<div className="specialization">
															{this.props.session.categoryName}
														</div>
													)}
												</span>
											</div>
										</div>
										<div className="col-lg-6 col-12">
											<div
												id="modifySession"
												/*onClick={this.popupPages}*/
												className="sessionInfo">
												<div id="modifySession" className="date">
													{moment(this.props.session.sessionFullDate)
														.tz(
															this.state.timeZone === "Africa/Cairo" &&
																isSummerTime
																? "Africa/Addis_Ababa"
																: this.state.timeZone
														)
														.format("dddd")}{" "}
													<br />
													{moment(this.props.session.sessionFullDate)
														.tz(
															this.state.timeZone === "Africa/Cairo" &&
																isSummerTime
																? "Africa/Addis_Ababa"
																: this.state.timeZone
														)
														.format("D")}{" "}
													{moment(this.props.session.sessionFullDate)
														.tz(
															this.state.timeZone === "Africa/Cairo" &&
																isSummerTime
																? "Africa/Addis_Ababa"
																: this.state.timeZone
														)
														.format("MMMM")}{" "}
													{","}
													{moment(this.props.session.sessionFullDate)
														.tz(
															this.state.timeZone === "Africa/Cairo" &&
																isSummerTime
																? "Africa/Addis_Ababa"
																: this.state.timeZone
														)
														.format("YYYY")}
													{"."}
												</div>
												<div id="modifySession" className="time">
													{moment(this.props.session.sessionFullDate)
														.tz(
															this.state.timeZone === "Africa/Cairo" &&
																isSummerTime
																? "Africa/Addis_Ababa"
																: this.state.timeZone
														)
														.format("hh:mm a")}
												</div>
											</div>
										</div>
										{/* <div className="col-lg-6 col-12">
											<div className="clientBox">
												<div className="title">Client Comments</div>
												<textarea
													value={this.state.clientComment}
													id="clientComment"
													onChange={this.changeHandler}
													readOnly={
														this.props.auth.authData &&
														this.props.auth.authData.userType === 2
													}
													className="form-control"
												/>
											</div>
										</div>
										<div className="col-lg-6 col-12">
											<div className="mentorBox">
												<div className="title">Guru Comments</div>
												<textarea
													value={this.state.mentorComment}
													id="mentorComment"
													onChange={this.changeHandler}
													readOnly={
														this.props.auth.authData &&
														this.props.auth.authData.userType === 1
													}
													className="form-control"
												/>
											</div>
										</div> */}
										<div className="chatting-box">
											{this.state.msgsContainer.map((msgDetails, msgIndex) => {
												const isSender =
													msgDetails.senderId ===
													this.props.auth.authData.user._id;
												return (
													<div
														ref={
															msgIndex === this.state.msgsContainer.length - 1
																? this.lastMsgRef
																: ""
														}
														className={`${
															!isSender && "flex-row-reverse isNotSender"
														} chatting-slot my-2`}>
														<div className="initials text-uppercase">
															{msgDetails.senderInitials}
														</div>
														<div className="msg-container mx-2 p-2">
															{msgDetails.message}
														</div>
													</div>
												);
											})}
										</div>
										<div className="send-box">
											<textarea
												value={this.state.msg}
												onChange={(e) => {
													this.setState({ msg: e.target.value });
												}}
												onKeyPress={(e) => {
													if (e.which === 13) {
														e.preventDefault();
														handleSendMessage();
													}
												}}
												placeholder="Type your message here..."
											/>
											<div className="divider"></div>
											<div className="d-flex justify-content-end">
												<button
													className="p-2 mainColor cursorPointer fw-500 send-button"
													onClick={handleSendMessage}>
													Send
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="btnContainer">
									{/* <button onClick={this.handleUpdateMsgs} className="btn">
										Update
									</button> */}
									<button
										onClick={this.props.session.canCancel && this.popupPages}
										id="cancelSession"
										className={
											this.props.session.canCancel ? "btn " : "btn disable"
										}>
										Cancel session
									</button>
								</div>
							</div>
						)}
					{this.state.sessionDetailsPage === "cancelSession" && (
						<div className="cancelSession">
							<div className="PersonInfo">
								{this.props.sessionType === "client" ? (
									<img
										className="initials"
										src={baseURL + "/" + this.props.session.coachImage}
										alt={baseURL + "/" + this.props.session.coachFullName}
									/>
								) : this.props.session.clientFullName ? (
									<div className="initials">
										{this.props.session.anonymous
											? "AN"
											: this.props.session?.clientFullName?.split(" ")[0][0] +
											  this.props.session?.clientFullName?.split(" ")[0][1]}
									</div>
								) : (
									<img
										className="initials"
										src={baseURL + "/" + this.props.session.coachImage}
										alt={this.props.session.coachFullName}
									/>
								)}

								<span className="info">
									<div
										className={
											this.props.sessionType === "coach"
												? "name client"
												: "name"
										}>
										{this.props.sessionType === "client"
											? this.props.session.prefixName +
											  " " +
											  this.props.session.coachFullName
											: this.props.session.anonymous
											? "Anonymous"
											: this.props.session.clientFullName}
									</div>
									{this.props.sessionType === "client" && (
										<div className="specialization">
											{this.props.session.categoryName}
										</div>
									)}
								</span>
							</div>
							<div
								id="modifySession"
								/*onClick={this.popupPages}*/
								className="sessionInfo">
								<div id="modifySession" className="date">
									{moment(this.props.session.sessionFullDate)
										.tz(this.state.timeZone)
										.format("dddd")}{" "}
									<br />
									{moment(this.props.session.sessionFullDate)
										.tz(this.state.timeZone)
										.format("D")}{" "}
									{moment(this.props.session.sessionFullDate)
										.tz(this.state.timeZone)
										.format("MMMM")}
									{","}
									{moment(this.props.session.sessionFullDate)
										.tz(this.state.timeZone)
										.format("YYYY")}
									{"."}
								</div>
								<div id="modifySession" className="time">
									{moment(this.props.session.sessionFullDate)
										.tz(this.state.timeZone)
										.format("hh:mm a")}
								</div>
							</div>
							<div className="buttonContainer">
								<div className="title">
									Are you sure you want to <br />
									cancel this session?
								</div>
								<div className="btnContainer">
									<Button className="btn">
										<div id="viewSessionDetails" onClick={this.popupPages}>
											No, Keep it
										</div>
									</Button>
									<Button
										loading={this.state.isLoading}
										onClick={this.handleCancelSession}
										className="btn">
										Cancel session
									</Button>
								</div>
							</div>
						</div>
					)}
					{this.state.sessionDetailsPage === "ConfirmCancelSession" && (
						<div className="confirmCancelSession">
							<img src={CancelSessionImg} alt="CancelSessionImg" />
							<div className="sessionInfo">
								<div className="date">
									{
										fullDayNames[
											new Date(this.props.session.sessionFullDate).getDay()
										]
									}{" "}
									<br />
									{new Date(this.props.session.sessionFullDate).getDate()}{" "}
									{
										fullMonthNames[
											new Date(this.props.session.sessionFullDate).getMonth()
										]
									}
									{","}
									{new Date(this.props.session.sessionFullDate).getFullYear()}
									{"."}
								</div>
							</div>
							<div className="PersonInfo">
								{this.props.sessionType === "client" ? (
									<img
										className="initials"
										src={baseURL + "/" + this.props.session?.coachImage}
										alt={baseURL + "/" + this.props.session?.coachFirstName}
									/>
								) : this.props.session.clientFullName ? (
									<div className="initials">
										{this.props.session.anonymous
											? "AN"
											: this.props.session.clientFullName.split(" ")[0][0] +
											  this.props.session.clientFullName.split(" ")[0][1]}
									</div>
								) : (
									<img
										className="initials"
										src={baseURL + "/" + this.props.session.clientImage}
										alt={this.props.session.clientFullName}
									/>
								)}

								<span className="info">
									<div className="name">
										{this.props.sessionType === "client"
											? this.props.session.prefixName +
											  " " +
											  this.props.session.coachFullName
											: this.props.session.anonymous
											? "Anonymous"
											: this.props.session.clientFullName}
									</div>
									{this.props.sessionType === "client" && (
										<div className="specialization">
											{this.props.session.categoryName}
										</div>
									)}
								</span>
							</div>

							<div
								className={`priceAndBtn my-2 ${
									this.props?.auth?.authData?.userType === 1
										? "visible"
										: "invisible"
								}`}>
								<span>Total Refund: </span>
								<span>
									{this.state.unit} {this.state.price}
								</span>
								<button onClick={this.props.handlePopup} className="btn">
									Close
								</button>
							</div>
						</div>
					)}
					{this.state.sessionDetailsPage === "modifySession" && (
						<div className="modifySession">
							<div className="PersonInfo">
								<span className="initials">MH</span>
								<span className="info">
									<div
										className={
											this.props.sessionType === "coach"
												? "name client"
												: "name"
										}>
										{" "}
										{this.props.session?.prefixName +
											" " +
											this.props.session.coachFullName}
									</div>
									<div className="specialization">Life Coach</div>
								</span>
							</div>
							<div className="dateTimeTitle">Date and Time</div>
							<Container>
								<Row>
									<Col md={6} sm={12}>
										<DatePicker
											value={this.state.time}
											isOpen={this.state.isOpen}
											isPopup={false}
											theme={this.state.theme}
											dateConfig={dateConfig}
											confirmText=""
											min={minDate}
											max={maxDate}
											headerFormat=""
											onChange={handleChange}
											showCaption={true}
										/>
										<div className="prevDayName">{this.state.prevDayName}</div>
										<div className="dayName">{this.state.dayName}</div>
										<div className="nextDayName">{this.state.nextDayName}</div>
									</Col>
									<Col md={6} sm={12}>
										<div className="timeSlots">
											<div className="container-fluid">
												<div className="row">
													{this.state.availableTimes.map((timeslot) => {
														return (
															<span>
																{!timeslot.booked && (
																	<div
																		key={timeslot.time}
																		className="col-6 timeStamp">
																		<div
																			onClick={handleTimeSlot}
																			id={timeslot.time + timeslot.period}
																			className={
																				this.state.timeSlot === timeslot.time
																					? "timeSlot  active"
																					: "timeSlot "
																			}>
																			{timeslot.time}
																		</div>
																	</div>
																)}
															</span>
														);
													})}
												</div>
											</div>
										</div>
									</Col>
								</Row>
							</Container>
							<div className="btnContainer">
								<button onClick={this.props.handlePopup} className="btn">
									Cancel
								</button>
								<button className="btn">Confirm</button>
							</div>
						</div>
					)}
				</Modal.Body>
			</Modal>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps)(ViewSessionModal);
