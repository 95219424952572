import React, { useEffect, useState } from "react";
import NavBarLg from "../../NavBarLg/NavBarLg";
import NavBarSm from "../../NavBarSm/NavBarSm";
import HamburgerList from "../../HamburgerList/HamburgerList";
import avatar from "../../../Assets/DefaultImg.png";
import eventChatIcon from "../../../Assets/event-chat-icon.svg";
// import moment from "moment";
import axios from "axios";
import EventBookingModal from "../Components/EventBookingModal/EventBookingModal";
import EventChattingModal from "../Components/EventChattingModal/EventChattingModal";
import { useLocation, useHistory } from "react-router";
import { connect } from "react-redux";
import { baseURL, exchangeCurrency, exchangeRate } from "../../../App";
import "./EventDetails.css";

function EventDetails({ auth }) {
  const [hamburgerMenu, toggleHamburgerMenu] = useState(false);
  const [isBookingModalVisible, updateIsBookingModalVisible] = useState(false);
  const [paymentData, updatePaymentData] = useState({});
  const [isBooked, updateIsBooked] = useState(false);
  const [isChattingModalVisible, updateIsChattingModalVisible] =
    useState(false);
  const [eventData, setEventData] = useState({});
  const history = useHistory();
  const onBookNowClick = () => {
    if (auth?.authData?.user) {
      updateIsBookingModalVisible(true);
    } else {
      history.push("/signin");
    }
  };
  const [titlesDescriptions, updateTitleDescription] = useState([]);
  const location = useLocation();
  const eventId = location?.state?.id;
  const timeZone = localStorage.getItem("timeZone");
  const callingEventData = () => {
    if (eventId) {
      var url = `${baseURL}/event/getEventById?eventId=${eventId}&timeZone=${timeZone}`;
      if (auth?.authData?.userType === 1) {
        url += `&clientId=${auth.authData.user._id}`;
      } else if (auth?.authData?.userType === 2) {
        url += `&guruId=${auth.authData.user._id}`;
      }

      axios({
        method: "get",
        url,
      }).then((res) => {
        const data = res.data.data;
        setEventData(data);
        updateIsBooked(data.isBooked || data.noOfSeats <= 0);

        const allTimes = [];
        data.eventDates.forEach((date) => {
          date.times.map((time) => {
            allTimes.push(time);
          });
        });

        const titlesAndDescriptions = [];
        titlesAndDescriptions.push([
          "",
          <>
            <img
              src={`${baseURL}/${data.guruImage}`}
              alt="avatar"
              className="guru-avatar"
            />
          </>,
        ]);
        titlesAndDescriptions.push(["", data.guruName]);
        titlesAndDescriptions.push(["", data.guruSpecialization]);
        titlesAndDescriptions.push([
          "Time",
          <ul className="time-list">
            {allTimes.map((time, index) => {
              const startDate = new Date(time.startDate);
              const endDate = new Date(time.endDate);
              // const showDates = startDate.toLocaleDateString("en-GB", {
              //   day: "2-digit",
              //   month: "2-digit",
              //   year: "numeric",
              // });

              const day = startDate.toLocaleDateString("en-GB", {
                day: "2-digit",
              });
              const month = startDate.toLocaleDateString("en-GB", {
                month: "2-digit",
              });
              const year = startDate.getFullYear();
              const weekday = startDate.toLocaleDateString("en-GB", {
                weekday: "long",
              });
              const startTime = startDate
                .toLocaleDateString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })
                .split(",")[1];
              const endTime = endDate
                .toLocaleDateString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })
                .split(",")[1];
              return (
                <li
                  className={`my-2 ${index % 2 ? "rose-bg" : ""}`}
                  key={time._id}
                  style={{ listStyle: "none" }}
                >
                  <span>
                    {`${day}/${month}/${year}`} {weekday} From {startTime} to{" "}
                    {endTime}
                  </span>
                </li>
              );
            })}
          </ul>,
        ]);
        titlesAndDescriptions.push(["Description", data.description]);
        if (data.status) {
          titlesAndDescriptions.push([
            "Status",
            <span className="mainColor">{data.status}</span>,
          ]);
        }
        titlesAndDescriptions.push([
          "Price",
          <span>
            {exchangeCurrency}
            {Math.ceil(data.eventCost * exchangeRate)}
          </span>,
        ]);
        if (data.location) {
          titlesAndDescriptions.push([
            "Location",
            <span className="mainColor">{data.location}</span>,
          ]);
        }
        titlesAndDescriptions.push([
          auth?.authData?.userType === 2 && data?.eventUsers?.length
            ? "Participants"
            : "",
          auth?.authData?.userType === 2 ? (
            <div className="d-flex justify-content-start">
              {data?.eventUsers?.map((user) => (
                <div
                  key={user.id}
                  className="d-flex align-items-center flex-column mx-2 cursorPointer"
                  onClick={() => {
                    updateIsChattingModalVisible(true);
                    updatePaymentData({ ...paymentData, userId: user.id });
                  }}
                >
                  <img
                    src={user.image ? baseURL + "/" + user.image : avatar}
                    alt="avatar"
                    className="client-avatar mb-2"
                  />
                  <div className="fw-600 f-23">{user.name}</div>
                </div>
              ))}
            </div>
          ) : data?.isBooked ? (
            <></>
          ) : (
            <button className="primary-btn py-2 mx-2" onClick={onBookNowClick}>
              Book Now
            </button>
          ),
        ]);

        updateTitleDescription(titlesAndDescriptions);
        updatePaymentData({ ...data, eventId });
      });
    }
  };
  useEffect(() => {
    callingEventData();
    // eslint-disable-next-line
  }, [eventId, exchangeCurrency, exchangeRate, auth?.authData?.userType]);
  const calculateTitlesHeight = () => {
    titlesDescriptions.forEach((title, titleIndex) => {
      const titleNode = document.querySelector(`#title-${titleIndex}`);

      const descriptionNode = document.querySelector(
        `#description-${titleIndex}`
      );
      if (titleNode && descriptionNode) {
        titleNode.style.height = descriptionNode.offsetHeight + "px";
      }
    });
  };
  useEffect(() => {
    window.addEventListener("resize", calculateTitlesHeight);
    calculateTitlesHeight();
    return () => {
      window.removeEventListener("resize", calculateTitlesHeight);
    };
    // eslint-disable-next-line
  }, [titlesDescriptions]);
  return (
    <div>
      <div>
        <NavBarLg
          active="Events"
          searchBar={false}
          fixedNavbar={true}
          parent={"SessionCalendar"}
        />
        <NavBarSm
          parent={"contact"}
          toggleHamburgerMenu={() => {
            toggleHamburgerMenu(true);
          }}
          toggleHamburgerMenuState={hamburgerMenu}
        />
        {hamburgerMenu && (
          <HamburgerList
            toggleHamburgerMenu={() => {
              toggleHamburgerMenu(false);
            }}
            toggleHamburgerMenuState={hamburgerMenu}
          />
        )}
        <div className="eventDetails m-0 m-md-4">
          <h4 className="header mainColor">Event Details</h4>
          <div className="details d-flex my-4">
            <div className="titles-container p-md-4 p-2">
              <ul className="p-md-4 p-2">
                {titlesDescriptions.map((title, idx) => (
                  <li id={`title-${idx}`} className="my-2 f-18 fw-600">
                    {title[0]}
                  </li>
                ))}
              </ul>
            </div>
            <div className="d-flex flex-1 p-md-4">
              <ul className="p-md-4 p-2">
                {titlesDescriptions.map((title, idx) => (
                  <li id={`description-${idx}`} className="my-2">
                    {title[1]}
                  </li>
                ))}
              </ul>
            </div>
            {auth.authData?.userType === 1 && isBooked && (
              <div
                className="chat-icon cursorPointer"
                onClick={() => {
                  updatePaymentData({
                    ...paymentData,
                    userId: auth.authData.user._id,
                  });
                  updateIsChattingModalVisible(true);
                }}
              >
                <img src={eventChatIcon} alt="eventChatIcon" />
              </div>
            )}
          </div>
        </div>
      </div>

      {auth.authData?.userType === 1 && (
        <EventBookingModal
          isModalVisible={isBookingModalVisible}
          onCancel={() => {
            updateIsBookingModalVisible(false);
            // callingEventData();
          }}
          paymentData={paymentData}
          auth={auth}
        />
      )}
      {auth.authData?.user && (
        <EventChattingModal
          paymentData={paymentData}
          isModalVisible={isChattingModalVisible}
          onCancel={() => {
            updateIsChattingModalVisible(false);
          }}
          auth={auth}
        />
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(EventDetails);
