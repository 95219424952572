import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { BookAction } from "../../Store/Actions/BookAction";
function Appointments({
  selectedDates,
  auth,
  currentDatesArr,
  hasSessions,
  nextSessionAv,
  firstName,
  lastName,
  requestASession,
  updateSameAccountModal,
  updateExact,
  updateRedirectStateSigniN,
  parent,
}) {
  const isClient = auth.authData && auth.authData.userType === 1;
  const isGuru = auth.authData && auth.authData.userType === 2;

  return (
    <div>
      {!hasSessions ? (
        <div className="text-center d-flex flex-grow-1 flex-column justify-content-center">
          <div
            className={parent === "DoctorDetails" ? "" : "d-none d-md-block"}
          >
            {nextSessionAv ? (
              <>
                <div>Next available session on:</div>
                <div
                  className="nextAvSession"
                  onClick={() => {
                    if (isClient) {
                      updateExact(nextSessionAv);
                    } else if (isGuru) {
                      updateSameAccountModal(true);
                    } else {
                      updateRedirectStateSigniN(nextSessionAv);
                    }
                  }}
                >
                  {moment(nextSessionAv.startDateTime).format(
                    "DD-MM-YYYY hh:mm a"
                  )}
                </div>
              </>
            ) : (
              <>
                <>No Available Sessions</>
                {(!auth.authData || auth.authData?.userType === 1) && (
                  <div
                    className="nextAvSession"
                    onClick={() => {
                      requestASession(`${firstName} ${lastName}`);
                    }}
                  >
                    Request a session{" "}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="availableTimes">
          <div className="row">
            <div className="col-1"></div>
            {selectedDates.map((selectedDate, i) => {
              return (
                <div key={i} className="col-2">
                  {selectedDate.length === 0 ? (
                    <div
                      className="time"
                      onClick={() => {
                        // if (isClient) {
                        // 	updateExact({ startDateTime: currentDatesArr[i] });
                        // } else if (isGuru) {
                        // 	updateSameAccountModal(true);
                        // } else {
                        // 	updateRedirectStateSigniN({
                        // 		startDateTime: currentDatesArr[i],
                        // 	});
                        // }
                      }}
                    >
                      -
                    </div>
                  ) : (
                    selectedDate.map((selectedDateTime, j) => {
                      return (
                        <div
                          key={j}
                          className="time"
                          onClick={() => {
                            if (isClient) {
                              updateExact(selectedDateTime);
                            } else if (isGuru) {
                              updateSameAccountModal(true);
                            } else {
                              updateRedirectStateSigniN(selectedDateTime);
                            }
                          }}
                        >
                          {moment(selectedDateTime.startDateTime).format(
                            "h:mm A"
                          )}
                        </div>
                      );
                    })
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    book: state.book,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    BookAction: (book) => dispatch(BookAction(book)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
