import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import RegReducer from "./RegReducers";
import BookReducer from "./BookReducer";
import currencyReducer from "./CurrencyReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
const persistConfig = {
  key: "root",
  storage,
  stateReconciler: hardSet,
};

const RootReducers = combineReducers({
  auth: AuthReducer,
  reg: RegReducer,
  book: BookReducer,
  currencyReducer: currencyReducer,
});
export default persistReducer(persistConfig, RootReducers);
