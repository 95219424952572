import React, { Component } from "react";
import Cross from "../../Components/svgLogos/Cross";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { AuthState } from "../../Store/Actions/AuthActions";
import SignOutLogo from "../svgLogos/HomePageFigures/SignOutLogo";
import { baseURL } from "../../App";
import "./HamburgerList.css";
export class HamburgerList extends Component {
  componentDidMount() {
    let upcommingBoxNode = document.querySelector(
      "#app>.nextSessionCounterBox"
    );
    if (upcommingBoxNode) {
      let chattingNode = document.querySelector(".chattingIcon");
      if (chattingNode) {
        upcommingBoxNode.classList.add("d-none");
        chattingNode.classList.add("d-none");
      }
    }
  }
  componentWillUnmount() {
    let upcommingBoxNode = document.querySelector(
      "#app>.nextSessionCounterBox"
    );
    if (upcommingBoxNode) {
      let chattingNode = document.querySelector(".chattingIcon");
      if (chattingNode) {
        upcommingBoxNode.classList.remove("d-none");
        chattingNode.classList.remove("d-none");
      }
    }
  }

  render(props) {
    return (
      <div className="hamburgerListPage">
        <span
          onClick={() => {
            this.props.toggleHamburgerMenu(
              !this.props.toggleHamburgerMenuState
            );
          }}
          className="cross"
        >
          <Cross />
        </span>
        {this.props.auth?.authData && (
          <div className="accountContainer">
            {this.props.auth?.authData.user.image === null ||
            this.props.auth?.authData.user.image.length === 0 ? (
              <span className="initials">
                {this.props.auth?.authData.user.firstName[0]}
                {this.props.auth?.authData.user.lastName[0]}
              </span>
            ) : (
              <img
                className="initials"
                src={baseURL + "/" + this.props.auth?.authData.user.image}
                alt={"firstName"}
              />
            )}
            <span className="name">
              {this.props.auth?.authData.user.firstName}
              <br />
              {this.props.auth?.authData.user.lastName}
            </span>
          </div>
        )}
        {this.props.auth?.authData && (
          <div className="menuItems">
            <ul className="list-unstyled">
              <li
                onClick={() => {
                  this.props.toggleHamburgerMenu(
                    !this.props.toggleHamburgerMenuState
                  );
                }}
              >
                <Link to="/">Home</Link>
              </li>
              {this.props.auth?.authData && (
                <li>
                  <Link to="/mySessions">My Sessions</Link>
                </li>
              )}
              {/* {this.props.auth?.authData && (
                <li>
                  <Link to="/events">Events</Link>
                </li>
              )} */}
              {this.props.auth?.authData && (
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
              )}
              {/* <li>
                <Link to="/events">Events</Link>
              </li> */}
              <li>
                {this.props.auth?.authData &&
                  this.props.auth?.authData.userType === 2 && (
                    <details>
                      <summary>My Account</summary>
                      <ul style={{ backgroundColor: "#f7f7f7" }}>
                        <li>
                          {/* <Link to="/account?accountTab=personalInfo">Personal Info</Link> */}
                          <Link
                            onClick={() => {
                              return (window.location.href =
                                "/account?accountTab=personalInfo");
                            }}
                          >
                            Personal Info
                          </Link>
                        </li>
                        <li>
                          {/* <Link to="/account?accountTab=profession">Professional Info</Link> */}
                          <Link
                            onClick={() => {
                              return (window.location.href =
                                "/account?accountTab=profession");
                            }}
                          >
                            Professional Info
                          </Link>
                        </li>
                        <li>
                          {/* <Link to="/account?accountTab=availability">Availability</Link> */}
                          <Link
                            onClick={() => {
                              return (window.location.href =
                                "/account?accountTab=availability");
                            }}
                          >
                            Availability
                          </Link>
                        </li>
                        <li>
                          {/* <Link to="/account?accountTab=bookingMessage">Booking Message</Link> */}
                          <Link
                            onClick={() => {
                              return (window.location.href =
                                "/account?accountTab=bookingMessage");
                            }}
                          >
                            Booking Message
                          </Link>
                        </li>
                        <li>
                          {/* <Link to="/account?accountTab=myReviews">My Reviews (Confidential)</Link> */}
                          <Link
                            onClick={() => {
                              return (window.location.href =
                                "/account?accountTab=myReviews");
                            }}
                          >
                            My Reviews (Confidential)
                          </Link>
                        </li>
                        <li>
                          {/* <Link to="/account?accountTab=myReviews">My Reviews (Confidential)</Link> */}
                          <Link
                            onClick={() => {
                              return (window.location.href =
                                "/account?accountTab=myDocuments");
                            }}
                          >
                            My Documents
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => {
                              return (window.location.href =
                                "/account?accountTab=bankDetails");
                            }}
                          >
                            Bank Details{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => {
                              return (window.location.href =
                                "/account?accountTab=packages");
                            }}
                          >
                            <span id="packages">Packages</span>
                          </Link>
                        </li>
                        <li>
                          {/* <Link to="/account?accountTab=transactionHistory">Transaction History</Link> */}
                          <Link
                            onClick={() => {
                              return (window.location.href =
                                "/account?accountTab=transactionHistory");
                            }}
                          >
                            Transaction History
                          </Link>
                        </li>
                      </ul>
                    </details>
                  )}

                {this.props.auth?.authData &&
                  // eslint-disable-next-line
                  this.props.auth?.authData.userType != 2 && (
                    <Link to="/account?accountTab=personalInfo">
                      My Account
                    </Link>
                  )}
              </li>
              {/* <li>WeLO for companies</li> */}
              <li>
                <a href="/contact">Contact us </a>

                {/* Contact us */}
              </li>
            </ul>
          </div>
        )}
        <div className="HamburgerListfooter">
          {this.props.auth?.authData ? (
            <div>
              <span
                onClick={() => {
                  this.props.AuthState(0);
                  this.props.toggleHamburgerMenu(
                    !this.props.toggleHamburgerMenuState
                  );
                }}
              >
                <Link to="/signin">
                  <SignOutLogo /> Log out
                </Link>
              </span>
              <Link to="/termsandconditions">Terms & Conditions</Link>
            </div>
          ) : (
            <span>
              <Link to="/signin">
                <span>Log in</span>
              </Link>
              <Link to="/usertype">
                <span>Join</span>
              </Link>
            </span>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    AuthState: (auth) => dispatch(AuthState(auth)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HamburgerList);
