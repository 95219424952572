import React, { useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { baseURL, exchangeCurrency, exchangeRate } from "../../../../../App";
import "./SmallEventCard.css";
import { Button } from "antd";
// import IMG from "../../../../../Assets/Hero Photo.png";

function SmallEventCard({ eventData }) {
  const [times, updateTimes] = useState([]);
  const {
    description,
    eventCost,
    eventDates,
    eventImage,
    guruImage,
    guruName,
    guruSpecialization,
    name,
    noOfSeats,
    _id,
    status,
  } = eventData;
  const history = useHistory();

  useEffect(() => {
    if (eventDates) {
      const allTimes = [];
      eventDates.forEach((date) =>
        date.times.map((time) => allTimes.push(time))
      );
      updateTimes(allTimes);
    }
  }, [eventDates]);
  return (
    <>
      {/*Start Banner */}
      <div className="container small-card__container">
        <div className="small-card">
          <div className="small-card__details">
            <h4 className="small-card__name">{name}</h4>
            <div className="small-card__description fw-400 f-18">
              {description}
            </div>
            <div>
              {noOfSeats <= 5 && (
                <div className="lastSeatsAlert fw-500 f-18">
                  {noOfSeats} seats left
                </div>
              )}
            </div>
          </div>
          <figure className="small-card__banner">
            <img src={`${baseURL}/${eventImage}`} alt="wallpaper" />
          </figure>
        </div>
        {/*End Banner */}

        {/* Guru Details */}
        <div className="event-container">
          <div className="event-content-container p-2">
            <div className="guru-details">
              {/*Start  */}
              <div className="center-content flex-column w-100">
                <img
                  className="avatar"
                  src={`${baseURL}/${guruImage}`}
                  alt="avatar"
                />

                <div className="text-center">
                  <div className="guru-name mainColor f-18 fw-500">
                    {guruName}
                  </div>
                  <div className="fw-500">{guruSpecialization}</div>
                </div>
              </div>
              {/* end */}
              {/*Start */}
              <div className="w-50 d-flex flex-column lign-items-center h-100">
                <div className="f-20 mainColor fw-500 mt-3">
                  {exchangeCurrency}
                  {Math.ceil(eventCost * exchangeRate)}/hr
                </div>
                {times?.map((date, index) => {
                  if (index > 1) {
                    return;
                  }
                  return (
                    <>
                      <span key={index}>
                        <span style={{ fontSize: "14px" }}>
                          {moment(date.startDate).format("dddd")}{" "}
                          {moment(date.startDate).format("DD/MM/YYYY")}
                        </span>{" "}
                        <br />
                        <span className="event-date-item">
                          {moment(date.startDate).format("LT")} -{" "}
                          {moment(date.endDate).format("LT")}
                        </span>
                        <br />
                      </span>
                      {index > 0 && (
                        <Button
                          className="all-days"
                          type="link"
                          style={{
                            color: "#cfa79d",
                            textAlign: "start",
                          }}
                          onClick={() => {
                            history.push(`/eventDetails?id=${_id}`, {
                              id: _id,
                            });
                          }}
                        >
                          All event days
                        </Button>
                      )}
                    </>
                  );
                })}

                <div
                  className="fw-500 f-18"
                  style={{ color: "#cfa79d", margin: "10px 0" }}
                >
                  {status}
                </div>
              </div>
              {/* end */}
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="divider mt-4"></div>
              <button
                className="primary-btn py-2 mt-2"
                onClick={() => {
                  history.push(`/eventDetails?id=${_id}`, { id: _id });
                }}
              >
                View Event
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SmallEventCard;
