import React, { useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { Alert } from "react-bootstrap";
import { BookAction } from "../../Store/Actions/BookAction";
import { baseURL, exchangeCurrency, exchangeRate } from "../../App";
import { connect } from "react-redux";
import ReactPixel from "react-facebook-pixel";
import "./styles.css";
import { Button } from "antd";
function PaymentForm({
  book,
  coupon,
  paymentData,
  setRedirectToPay,
  onClick,
  auth,
  checkValidEmail,
  mode,
  BookAction,
  back,
  isPackage,
  isEvent,
}) {
  const [loadingState, updateLoadingState] = useState(false);
  const [errMsg, updateErrMsg] = useState(null);
  const [isCCCompleted, updateIsCCCompleted] = useState(false);
  const stripe = useStripe();
  const timeZone = localStorage.getItem("timeZone");
  const elements = useElements();
  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#cfa79d",
        color: "#000",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "#e8e8e8" },
        "::placeholder": { color: "#e8e8e8" },
      },
      invalid: {
        iconColor: "red",
        color: "red",
      },
    },
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mode) {
      const isEmailConfirmedVar = await checkValidEmail();
      if (!isEmailConfirmedVar) return;
    }
    updateLoadingState(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    // if (!error && paymentData.fullDiscount) {
    if (!error) {
      try {
        const { id } = paymentMethod;
        if (isEvent) {
          axios({
            method: "post",
            //URL For Book Event
            url: `${baseURL}/payment/payEvent`,
            data: {
              id,
              eventId: paymentData.eventId,
              coachId: paymentData.guruId,
              clientId: auth.authData.user._id,
              exchangeRate,
              currency: exchangeCurrency,
              noOfReservedSeats: paymentData.noOfReservedSeats,
            },
          })
            .then((res) => {
              setRedirectToPay(true);
            })
            .catch((err) => {
              setRedirectToPay(false);
            });
        } else if (book.isPackage) {
          axios({
            method: "post",
            url: baseURL + "/payment/payCoachPackage",
            data: {
              id,
              packageId: book._id,
              coachId: book.coachId,
              clientId: auth.authData.user._id,
              currency: exchangeCurrency,
              exchangeRate: exchangeRate,
              coupon,
            },
          })
            .then((res) => {
              if (res.data.code === 1) {
                localStorage.setItem("transactionState", "Success");
                setRedirectToPay();
              } else {
                localStorage.setItem("transactionState", "Fail");
                setRedirectToPay();
              }
            })
            .catch((error) => {
              localStorage.setItem("transactionState", "Fail");
              setRedirectToPay();
            });
        } else {
          axios({
            method: "post",
            url: baseURL + "/payment/paymentNew",
            data: {
              ...paymentData,
              id,
              coupon,
            },
          }).then((res) => {
            if (res.data.success) {
              const amount = res.data.amount;
              axios({
                method: "post",
                url: baseURL + "/user/bookSessionNew",
                data: {
                  sessionTopic: book.sessionTopic,
                  coachId: book.coachId,
                  clientId: auth.authData.user._id,
                  anonymous: book.anonymous,
                  price: amount,
                  userType: auth.authData.userType,
                  paymentNumber: 0, //payment number
                  coachTimeId: book.coachTimeId,
                  timeZone,
                  periodMin: book.period === 30 ? 30 : 60,
                  period: book.period === 30 ? 0 : 1,
                  currency: exchangeCurrency,
                  coupon: res.data.coupon,
                },
              })
                .then((res) => {
                  ReactPixel.track("Success Payment", {
                    name: auth.authData.user.fullName,
                    id: auth.authData.user._id,
                    amount: amount,
                    currency: timeZone === exchangeCurrency,
                  });
                  axios({
                    method: "post",
                    url: baseURL + "/account/NotifyChangeSchedule",
                  }).then((res) => {});
                  localStorage.setItem("transactionState", "Success");
                  BookAction({
                    userToken: res.data.data.usertoken,
                    isPackage,
                    sessionId: res.data.data._id,
                  });

                  setRedirectToPay();
                })
                .catch((error) => {
                  localStorage.setItem("transactionState", "Fail");
                  setRedirectToPay();
                });
            } else {
              localStorage.setItem("transactionState", "Fail");
              setRedirectToPay();
            }
          });
        }
      } catch (error) {
        localStorage.setItem("transactionState", "Fail");
        setRedirectToPay();
      }
    } else {
      updateErrMsg(error.message);
      localStorage.setItem("transactionState", "Fail");
      setRedirectToPay();
    }
  };

  const { fullDiscount } = paymentData;

  useEffect(() => {
    updateIsCCCompleted(fullDiscount);
  }, [fullDiscount]);

  const handleSubmitEvent = async (event) => {
    event.preventDefault();
    updateLoadingState(true);
    axios({
      method: "post",
      //URL For Book Event
      url: `${baseURL}/payment/payEvent`,
      data: {
        eventId: paymentData.eventId,
        coachId: paymentData.guruId,
        clientId: auth.authData.user._id,
        exchangeRate,
        currency: exchangeCurrency,
        coupon,
        noOfReservedSeats: paymentData.noOfReservedSeats,
      },
    })
      .then((res) => {
        setRedirectToPay(true);
      })
      .catch((err) => {
        console.log(err);
        setRedirectToPay(false);
      });
  };

  return (
    <>
      <div className={!loadingState ? "d-none" : "loader"}></div>
      {!paymentData.fullDiscount && (
        <form
          onSubmit={handleSubmit}
          className={loadingState ? "d-none" : "paymentForm"}
        >
          <fieldset className="FormGroup">
            <div className="FormRow">
              <CardElement
                style={{ display: "none" }}
                onChange={(e) => {
                  updateIsCCCompleted(e.complete);
                }}
                options={CARD_OPTIONS}
              />
            </div>
          </fieldset>
          <div className="text-center">
            {back ? (
              <button
                className="btn"
                onClick={onClick}
                id={back ? back : "confirmSessionDetails"}
              >
                Back
              </button>
            ) : (
              <></>
            )}
            <button className="btn" type={"submit"} disabled={!isCCCompleted}>
              <span>Pay</span>
            </button>
          </div>
          {errMsg !== null && (
            <Alert variant={"danger"} className="mt-2">
              {errMsg}
            </Alert>
          )}
        </form>
      )}
      {paymentData.fullDiscount && (
        <Button className="confirm-reseve" onClick={handleSubmitEvent}>
          Confirm
        </Button>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    book: state.book,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    BookAction: (book) => dispatch(BookAction(book)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
