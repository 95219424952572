import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { baseURL } from "../../App";
import NavbarLg from "../../Components/NavBarLg/NavBarLg";
import Footer from "../../Components/Footer/Footer";
import NavBarSm from "../../Components/NavBarSm/NavBarSm";
import FilterModal from "../../Components/FilterModal/FilterModal";
import DropDownModal from "../../Components/DropDownModal/DropDownModal";
import HamburgerList from "../../Components/HamburgerList/HamburgerList";
import MessSuccModal from "../../Components/MessSuccessModal/MessSuccModal";
import Close from "../../Components/svgLogos/Cross";
import { Link, Redirect } from "react-router-dom";
import { Alert } from "react-bootstrap";
import "./Contact.css";

class Contact extends Component {
	state = {
		Show: null,
		err: null,
		errMess: false,
		showMess: false,
		theposition: window.pageYOffset,
		toggleFilterPage: false,
		toggleDropdownMenu: false,
		toggleHamburgerMenu: false,
		name: this.props.auth.authData
			? this.props.auth.authData.user.fullName
			: "",
		email: this.props.auth.authData ? this.props.auth.authData.user.email : "",
		mobile: this.props.auth.authData ? this.props.auth.authData.user.phone : "",
		message: "",
		requesterId: "",
		isModalVisible: false,
		emptyState: false,
		isValidEmail: true,
	};
	toggleFilterPage = (val) => {
		this.setState({ toggleFilterPage: val });
	};
	toggleDropdownMenu = (val) => {
		this.setState({ toggleDropdownMenu: val });
	};
	filteredPagesCount = (val) => {
		this.setState({ pagesCount: val });
	};
	filteredCoaches = (val) => {
		this.setState({ coaches: val, filteredPagesMode: true, index: 1 });
	};
	toggleHamburgerMenu = (val) => {
		this.setState({ toggleHamburgerMenu: val });
	};

	getData = (e) => {
		if (e.target.name === "email") {
			const isValidEmail =
				/^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/.test(
					e.target.value
				);

			this.setState({ isValidEmail });
		}
		this.setState({ [e.target.name]: e.target.value });
	};
	sendData = (e) => {
		e.preventDefault();
		if (
			this.state.mobile &&
			this.state.email &&
			this.state.name &&
			this.state.message &&
			this.state.isValidEmail
		) {
			this.setState({ emptyState: false });
			axios({
				method: "post",
				url: baseURL + "/account/ConatctUs",
				data: {
					mobile: this.state.mobile,
					email: this.state.email.toLowerCase(),
					name: this.state.name,
					message: this.state.message,
					requesterId: this.props.auth?.authData?.user._id,
				},
			})
				.then((res) => {
					this.setState({
						// Show:res.data,
						errMess: false,
						message: "",
						isModalVisible: true,
					});
				})
				.catch((error) => {
					if (error.response.data.code === -1) {
						this.setState({
							errMess: true,
							showMess: false,
							isModalVisible: true,
							error: error.response.data.message,
						});
					}
				});
			setTimeout(() => {
				this.setState({
					isModalVisible: false,
				});
			}, 3000);
		} else {
			this.setState({ emptyState: true });
		}
	};
	componentDidMount() {
		window.scrollTo(0, 0);
		if (this.props.location.state) {
			this.setState({
				message: this.props.location.state.message,
			});
		}
	}
	render(props) {
		const toggleModal = () => {
			this.setState({ isModalVisible: false, redirectState: true });
		};
		if (this.state.redirectState) {
			return <Redirect to="/" />;
		}

		return (
			<div className="contact">
				<NavbarLg
					active="Account"
					toggleFilterPageState={this.state.toggleFilterPage}
					toggleFilterPage={this.toggleFilterPage}
					toggleDropdownMenuState={this.state.toggleDropdownMenu}
					toggleDropdownMenu={this.toggleDropdownMenu}
					searchBar={false}
					fixedNavbar={true}
				/>
				<FilterModal
					filterState={this.state.toggleFilterPage}
					filteredprops={this.filteredprops}
					filteredPagesCount={this.filteredPagesCount}
					toggleFilterPage={this.toggleFilterPage}
					filteredCoaches={this.filteredCoaches}
				/>
				<DropDownModal
					dropDownState={this.state.toggleDropdownMenu}
					toggleDropdownMenu={this.toggleDropdownMenu}
				/>
				{this.state.toggleHamburgerMenu && (
					<HamburgerList
						toggleHamburgerMenu={this.toggleHamburgerMenu}
						toggleHamburgerMenuState={this.state.toggleHamburgerMenu}
					/>
				)}
				<NavBarSm
					toggleFilterPage={this.toggleFilterPage}
					toggleHamburgerMenu={this.toggleHamburgerMenu}
					toggleHamburgerMenuState={this.state.toggleHamburgerMenu}
					searchedCoachList={this.searchedCoachList}
					searchedPagesCount={this.filteredPagesCount}
					parent="contact"
				/>

				<div className="container">
					<p className="title">
						Leave your message and we'll contact you soon.
					</p>
					<Link to="/" className="closeBtn">
						<Close />
					</Link>
					<div className="form w-50 mx-auto my-4">
						<form onSubmit={this.sendData}>
							<input
								onChange={this.getData}
								type="text"
								placeholder="Enter your Name*"
								className={`form-control mt-3 py-4 pl-3 border ${
									this.state.emptyState && !this.state.name && `border-danger`
								}`}
								name="name"
								value={this.state.name}
							/>
							<input
								onChange={this.getData}
								type="email"
								placeholder="Enter your Email*"
								className={`form-control mt-3 py-4 pl-3 border ${
									this.state.emptyState &&
									(!this.state.email || !this.state.isValidEmail) &&
									`border-danger`
								}`}
								name="email"
								value={this.state.email}
							/>
							<input
								onChange={this.getData}
								type="text"
								placeholder="Enter your Number*"
								className={`form-control mt-3 py-4 pl-3 border ${
									this.state.emptyState && !this.state.mobile && `border-danger`
								}`}
								name="mobile"
								value={this.state.mobile}
							/>
							<textarea
								onChange={this.getData}
								className={`form-control mt-3 border ${
									this.state.emptyState &&
									!this.state.message &&
									`border border-danger`
								}`}
								rows="5"
								col="12"
								placeholder="Your message here*"
								name="message"
								value={this.state.message}
							></textarea>
							<button
								type="submit"
								className="btn mt-4"
								style={{ width: "54%" }}
							>
								Submit
							</button>
						</form>
						{this.state.emptyState && (
							<Alert className="text-center my-2" variant="danger">
								Please Fill All The Required Fields!
							</Alert>
						)}
						<MessSuccModal
							isModalVisible={this.state.isModalVisible}
							toggleModal={() => toggleModal()}
						/>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};
export default connect(mapStateToProps)(Contact);
