import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import NavbarLg from "../../Components/NavBarLg/NavBarLg";
import AccountSideMenu from "../../Components/AccountSideMenu/AccountSideMenu";
import FilterModal from "../../Components/FilterModal/FilterModal";
import HamburgerList from "../../Components/HamburgerList/HamburgerList";
import FloatingBar from "../../Components/FloatingBar/FloatingBar";
import AccountHeader from "../../Components/AccountHeader/AccountHeader";
import AccountPersonalInfo from "../../Components/AccountPersonalInfo/AccountPersonalInfo";
import AccountAvailability from "../../Components/AccountAvailability/AccountAvailability";
import AccountProfession from "../../Components/AccountProfession/AccountProfession";
import AccountTransactionHistory from "../../Components/AccountTransactionHistory/AccountTransactionHistory";
import AccountDiscounts from "../../Components/AccountDiscounts/AccountDiscounts";
import AccountBookingMessage from "../../Components/AccountBookingMessage/AccountBookingMessage";
import AccountMyReviews from "../../Components/AccountMyReviews/AccountMyReviews";
import AccountDocuments from "../../Components/AccountDocuments/AccountDocuments";
import DropDownModal from "../../Components/DropDownModal/DropDownModal";
import AccountBankDetails from "../../Components/AccountBankDetails/AccountBankDetails";
import Packages from "../../Components/Packages/Packages";
import "./AccountPage.css";

class AccountPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageName: "personalInfo",
			toggleFilterPage: false,
			toggleDropdownMenu: false,
			toggleHamburgerMenu: false,
			alert: false,
			childClick: "",
			showalert: false,
			save: false,
			target: "",
			drawerState: false,
		};
		//this.myRef = React.createRef();
	}

	alertMess = () => {
		// if (this.state.alert === true) {
		// 	this.setState({
		// 		showalert: true,
		// 	});
		// }
	};
	leave = () => {
		this.setState({
			showalert: false,
			pageName: this.state.target,
			alert: false,
		});
	};
	handleClose = () => {
		this.setState({
			showalert: false,
		});
	};
	alertPages = (val) => {
		this.setState({
			showalert: val,
		});
	};
	switchPages = (val) => {
		this.setState({ pageName: val });
	};
	toggleFilterPage = (val) => {
		this.setState({ toggleFilterPage: val });
	};
	toggleDropdownMenu = (val) => {
		this.setState({ toggleDropdownMenu: val });
	};
	toggleHamburgerMenu = (val) => {
		this.setState({ toggleHamburgerMenu: val });
	};

	filteredPagesCount = (val) => {
		this.setState({ pagesCount: val });
	};
	filteredCoaches = (val) => {
		this.setState({ coaches: val, filteredPagesMode: true, index: 1 });
	};

	handleCallback = (childData) => {
		this.setState({
			alert: childData,
		});
	};

	componentDidMount() {
		const accountTab = window.location.search;
		const params = new URLSearchParams(accountTab);
		const accountTabValue = params.get("accountTab");
		if (accountTabValue) {
			this.setState({
				pageName: accountTabValue,
			});
		}
	}

	render(props) {
		// setTimeout(() => {
		// 	const upcomingSessionNode = document.querySelector(
		// 		".sessionCountdownContainer"
		// 	);
		// 	if (upcomingSessionNode) {
		// 		upcomingSessionNode.style.top = "156px";
		// 	}
		// }, 1000);
		if (this.props.auth.authData === undefined) {
			localStorage.setItem("redirectState", true);
			return <Redirect to="/signin" />;
		}
		return (
			<div
				className={
					this.state.toggleFilterPage ||
					this.state.toggleDropdownMenu ||
					this.state.toggleHamburgerMenu
						? "filterPageActive accountPage"
						: "accountPage"
				}>
				<NavbarLg
					active="Account"
					toggleFilterPageState={this.state.toggleFilterPage}
					toggleFilterPage={this.toggleFilterPage}
					toggleDropdownMenuState={this.state.toggleDropdownMenu}
					toggleDropdownMenu={this.toggleDropdownMenu}
					searchBar={false}
					fixedNavbar={true}
					getFilterState={(val) => {}}
				/>
				{/*this.state.toggleFilterPage && (
					<FilterComponent
						filteredprops={this.filteredprops}
						filteredPagesCount={this.filteredPagesCount}
						toggleFilterPage={this.toggleFilterPage}
						filteredCoaches={this.filteredCoaches}
					/>
				)*/}
				<FilterModal
					filterState={this.state.toggleFilterPage}
					filteredprops={this.filteredprops}
					filteredPagesCount={this.filteredPagesCount}
					toggleFilterPage={this.toggleFilterPage}
					filteredCoaches={this.filteredCoaches}
				/>
				{/*this.state.toggleDropdownMenu && (
					<DropDownMenu toggleDropdownMenu={this.toggleDropdownMenu} />
				)*/}
				<DropDownModal
					dropDownState={this.state.toggleDropdownMenu}
					toggleDropdownMenu={this.toggleDropdownMenu}
				/>
				{this.state.toggleHamburgerMenu && (
					<HamburgerList
						toggleHamburgerMenu={this.toggleHamburgerMenu}
						toggleHamburgerMenuState={this.state.toggleHamburgerMenu}
					/>
				)}

				<AccountHeader
					openDrawer={(val) => {
						//myRef.current.showDrawer();
						this.setState({ drawerState: val });
					}}
					toggleHamburgerMenu={this.toggleHamburgerMenu}
					drawerState={this.state.drawerState}
				/>
				<div className="container-fluid">
					<div className="row ">
						<div className="col-3 account-side-menu" onclick={this.alertmess}>
							<AccountSideMenu
								drawerState={this.state.drawerState}
								onSwitchPages={this.switchPages}
								alert={this.state.alert}
								showalert={this.state.showalert}
								alertPages={this.alertPages}
								save={this.state.save}
								targetPage={(val) => {
									this.setState({ target: val });
								}}
								toggleSave={(val) => {
									this.setState({ save: val });
								}}
								activePage={this.state.pageName}
							/>
						</div>
						{this.state.pageName === "personalInfo" && (
							<div className="col-12 col-md-9">
								<AccountPersonalInfo
									pageName={this.state.pageName}
									parentCallback={this.handleCallback}
									showalert={this.state.showalert}
								/>
							</div>
						)}
						{this.state.pageName === "availability" && (
							<div className="col-12 col-md-9">
								<AccountAvailability parentCallback={this.handleCallback} />
							</div>
						)}
						{this.state.pageName === "profession" && (
							<div className="col-12 col-md-9">
								<AccountProfession parentCallback={this.handleCallback} />
							</div>
						)}
						{this.state.pageName === "transactionHistory" && (
							<div className="col-12 col-md-9">
								<AccountTransactionHistory />
							</div>
						)}
						{this.state.pageName === "AccountDiscounts" && (
							<div className="col-12 col-md-9">
								<AccountDiscounts parentCallback={this.handleCallback} />
							</div>
						)}
						{this.state.pageName === "bankDetails" && (
							<AccountBankDetails parentCallback={this.handleCallback} />
						)}
						{this.state.pageName === "bookingMessage" && (
							<div className="col-12 col-md-9">
								<AccountBookingMessage parentCallback={this.handleCallback} />
							</div>
						)}
						{this.state.pageName === "myReviews" && (
							<div className="col-12 col-md-9">
								<AccountMyReviews />
							</div>
						)}
						{this.state.pageName === "myDocuments" && (
							<div className="col-12 col-md-9">
								<AccountDocuments />
							</div>
						)}
						{this.state.pageName === "packages" && (
							<div className="col-12 col-md-9  p-0">
								<Packages />
							</div>
						)}
					</div>
				</div>

				{this.state.showalert && (
					<Modal show={this.state.showalert} onHide={this.handleClose}>
						<Modal.Header closeButton className="border-0">
							{/* <Modal.Title>Modal heading</Modal.Title> */}
						</Modal.Header>
						<Modal.Body className="text-center">
							Are You Sure you want to leave this page without saving your edits
							?
						</Modal.Body>
						<Modal.Footer className="border-0 m-auto py-4">
							<Button
								variant="secondary"
								onClick={this.handleClose}
								className="Cancel">
								Cancel
							</Button>
							<Button onClick={this.leave} className="leave">
								Leave
							</Button>
						</Modal.Footer>
					</Modal>
				)}

				<FloatingBar active="account" />
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps)(AccountPage);
