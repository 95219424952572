import React, { useState, useEffect, useLayoutEffect } from "react";
import CountDownTimer from "./Components/CountDownTimer/CountDownTimer";
import { Route, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { AuthState } from "././Store/Actions/AuthActions";
import WelcomeModule from "./Components/WelcomeModule/WelcomeModule";
import moment from "moment";
import ViewSessionModal from "./Components/ViewSessionModal/ViewSessionModal";
import ReactPixel from "react-facebook-pixel";
import Routes from "./Routes";
import { currencyActions } from "./Store/Actions/CurrencyActions";
import "./firebase";
import "./App.css";
import "moment-timezone";
import "antd/dist/antd.css";
import "react-notifications/lib/notifications.css";

export const baseURL = process.env.REACT_APP_BASE_URL;

export var exchangeRate = 1;
export var exchangeCurrency = "$";
export const isSummerTime = false;
const App = (props) => {
  const [rerenderState, updateRerenderState] = useState(false);
  const [upcomingDate, UpdateUpcomingDate] = useState("");
  const [serverDate, UpdateServerDate] = useState(null);
  const [stillPendingFlag, updateStillPendingFlag] = useState(false);
  const [upcommingSessionDetails, UpdateUpcommingSessionDetails] =
    useState(null);
  const [endUpcomingDate, UpdateEndUpcomingDate] = useState(null);
  const [upcommingSessionID, UpdateUpcommingSessionID] = useState(null);
  const [sessionHight, updateSessionHight] = useState(false);
  const [homeRerenderState, updateHomeRerenderState] = useState(false);
  const [hasUnreadMsg, updateHasUnreadMsg] = useState(false);
  //const [sessionStarted, UpdateSessionStarted] = useState(false);
  //const [token, UpdateToken] = useState(null);
  const [currency, updateCurrency] = useState(true);
  const [sessionDetailsPopup, UpdateSessionDetailsPopup] = useState(false);
  // eslint-disable-next-line
  const history = useHistory();
  const timeZone = localStorage.getItem("timeZone");
  // eslint-disable-next-line
  const [test, updateTest] = useState(false);

  //Start Meta Pixel Integration
  const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init("5205634122821222", advancedMatching, options);

  ReactPixel.pageView(); // For tracking page view
  //End Meta Pixel Integration

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currencyResponse = await axios.get("https://ipapi.co/currency/");
        const currency = currencyResponse.data;
        // console.log("🚀 ~ file: App.js:62 ~ fetchData ~ curr:", currency);
        // updateCurrency(curr);

        const exchangeRateResponse = await axios.get(
          `${baseURL}/change/exchangeRate?from=USD&to=${currency}`
        );

        exchangeRate = exchangeRateResponse.data.data;
        exchangeCurrency = currency;

        window.localStorage.setItem(
          "exchangeRate",
          exchangeRateResponse.data.data || 1
        );
        window.localStorage.setItem("exchangeCurrency", currency || "USD");
        props.currencyActions({ exchangeRate, exchangeCurrency });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // var curr;
    // axios({
    //   method: "get",
    //   url: "https://ipapi.co/currency/",
    // }).then((res) => {
    //   curr = res.data;
    //   updateCurrency(curr);
    //   axios({
    //     method: "get",
    //     url: `${baseURL}/change/exchangeRate?from=USD&to=${curr}`,
    //   }).then((res) => {
    //     console.log(res);
    //     exchangeRate = res.data.data;
    //     exchangeCurrency = curr;
    //     props.currencyActions({ exchangeRate, exchangeCurrency });
    //     // updateTest((test) => !test);
    //   });
    // });

    // console.log(currency);

    // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // localStorage.setItem("timeZone", timeZone);
    // if (props.auth.authData && props.auth.authData.userType === 2 && timeZone) {
    // 	axios({
    // 		method: "post",
    // 		url: baseURL + `/coache/UpdateTimeZone`,
    // 		data: {
    // 			timeZone: timeZone,
    // 			coachId: props.auth.authData.user._id,
    // 		},
    // 	});
    // }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   exchangeRate = localStorage.getItem("exchangeRate");
  //   exchangeCurrency = localStorage.getItem("exchangeCurrency");
  // }, []);

  // useEffect(() => {
  //   if (currency) {
  //     axios({
  //       method: "get",
  //       url: `${baseURL}/change/exchangeRate?from=USD&to=${currency}`,
  //     }).then((res) => {
  //       console.log(res);
  //       exchangeRate = res.data.data;
  //       exchangeCurrency = currency;
  //       props.currencyActions({ exchangeRate, exchangeCurrency });
  //       updateTest((test) => !test);
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [currency]);

  useEffect(() => {
    axios({
      method: "get",
      // url: "https://timezoneapi.io/api/ip/?token=ayCQrOchbzlzMKjSqmws",
      url: "https://api.ipgeolocation.io/ipgeo?apiKey=73ee0cd63359478b84d2231ba27b0f17",
    }).then((res) => {
      console.log(res);
      const timeZone =
        //   res.data.time_zone.name === "Africa/Cairo" && isSummerTime
        //     ? "Africa/Addis_Ababa"
        //     :
        res.data.time_zone.name;
      if (props.auth?.authData) {
        axios({
          method: "post",
          url: baseURL + `/coache/UpdateTimeZone`,
          data: {
            timeZone: timeZone,
            coachId: props.auth?.authData?.user?._id,
          },
        }).then((res) => {});
      }
      if (timeZone !== localStorage.getItem("timeZone")) {
        window.localStorage.setItem("timeZone", timeZone);
        window.location.reload();
      }
    });
    // eslint-disable-next-line
  }, [history.location.pathname]);
  const getUpcommingSessionHight = (state) => {
    updateSessionHight(state);
  };
  // const handleGoStream = () => {
  // 	const username = props.auth.authData.user._id;
  // 	const roomName = upcommingSessionID;
  // 	const URL = baseURL + `/video/token`;
  // 	axios({
  // 		method: "post",
  // 		url: URL,
  // 		data: {
  // 			identity: username,
  // 			room: roomName,
  // 			nbf: upcomingDate,
  // 			ttl: upcommingSessionDetails.period === 1 ? 3600 : 1800,
  // 			exp: endUpcomingDate,
  // 		},
  // 	}).then((response) => {
  // 		UpdateToken(response.data.token);
  // 		window.location.href = `room/${response.data.token}?sessionId=${roomName}`;
  // 	});
  // };

  const handlePopup = () => {
    UpdateSessionDetailsPopup(!sessionDetailsPopup);
  };

  if (rerenderState) {
    window.location.reload();
    updateRerenderState(false);
  }

  //Check if coach is blocked or not
  useEffect(() => {
    if (props.auth.authData) {
      axios({
        method: "post",
        url: baseURL + "/coache/CheckCoachStatus",
        data: {
          id: props.auth?.authData?.user?._id,
          userType: props.auth.authData.userType,
        },
      }).then((res) => {
        if (!res.data.data.isActiveAndConfirmed) {
          props.AuthState(0);
        }
        updateStillPendingFlag(res.data.data.isActiveAndPending);
      });
    }
  }, [props]);
  // useEffect(() => {
  // 	if (props.auth.authData && adjustCountdown) {
  // 		// moment(this.state.serverDate).tz(this.state.timeZone, true).format() <
  // 		// moment(this.state.endUpcomingDate).tz(this.state.timeZone).format()

  // 		axios({
  // 			method: "get",
  // 			url: baseURL + `/account/GetServerTime?timeZone=${timeZone}`,
  // 		}).then((res) => {
  // 			UpdateServerDate(res.data);

  // 			// this.setState({ serverDate: res.data },  () => {
  // 			// eslint-disable-next-line
  // 			const date = new Date(stateDate)
  // 				.toLocaleString("en-US", {
  // 					timeZone: timeZone,
  // 				})
  // 				.split(",")[0];
  // 			// this.props.auth.authData !== undefined &&
  // 			// 	axios({
  // 			// 		method: "get",
  // 			// 		url: `${baseURL}/user/ClientSessionsNew?clientId=${this.props.auth.authData.user._id}&sessionDate=${date}&userType=${this.props.auth.authData.userType}&timeZone=${this.state.timeZone}`,
  // 			// 	}).then((res) => {
  // 			// 		this.setState({
  // 			// 			coachSessions: res.data.coach,
  // 			// 			clientSessions: res.data.client,
  // 			// 		});
  // 			// 	});

  // 			// });
  // 		});
  // 	}
  // 	// eslint-disable-next-line
  // }, [props.auth.authData, serverDate, upcomingDate, endUpcomingDate]);

  useEffect(() => {
    if (props?.auth?.authData && timeZone) {
      axios({
        method: "get",
        url:
          baseURL +
          "/user/UpcomingSessionNew?clientId=" +
          props?.auth?.authData?.user?._id +
          "&userType=" +
          props?.auth?.authData?.userType +
          "&timeZone=" +
          timeZone,
      }).then((res) => {
        // UpdateToken(
        // 	res.data.coach
        // 		? res.data.coach[0]?.coachToken
        // 		: res.data.client[0]?.userToken
        // );
        UpdateUpcommingSessionDetails(
          res.data.coach ? res.data.coach[0] : res.data.client[0]
        );
        UpdateUpcomingDate(
          res.data.client.length > 0 || res.data.coach.length > 0
            ? res.data.client.length > 0
              ? moment(res.data.client[0].sessionFullDate).format()
              : moment(res.data.coach[0].sessionFullDate).format()
            : null
        );

        UpdateEndUpcomingDate(
          res.data.client.length > 0 || res.data.coach.length > 0
            ? res.data.client.length > 0
              ? moment(res.data.client[0].sessionEndDate).format()
              : moment(res.data.coach[0].sessionEndDate).format()
            : null
        );
        UpdateUpcommingSessionID(
          res.data.client.length > 0 || res.data.coach.length > 0
            ? res.data.coach.length > 0
              ? res.data.coach[0]._id
              : res.data.client[0]._id
            : null
        );
      });
    }
    // eslint-disable-next-line
  }, [props.auth.authData, timeZone, homeRerenderState, sessionDetailsPopup]);
  // start test mode
  // const getCoachList = () => {
  // 	axios({
  // 		method: "get",
  // 		url: baseURL + "/coache/coachesListNew?index=0&timeZone=" + timeZone,
  // 	}).then((res) => {
  // 		const allCoaches = res.data;
  // 		allCoaches.forEach((coach, coachIndex) => {
  // 			const coachId = coach._id;
  // 			if (coach.email !== "aishaamerphotography@gmail.com") {
  // 				axios({
  // 					method: "get",
  // 					url: baseURL + `/coache/getCoachSchedules?coachId=${coachId}`,
  // 				}).then((res) => {
  // 					const allSchedules = [...res.data.data.schedules];
  // 					let recursiveSchedulesList = [];

  // 					const daysNames = [];
  // 					for (let dayNumber = 0; dayNumber <= 6; dayNumber++) {
  // 						const dayName = moment(serverDate)
  // 							.add(dayNumber, "days")
  // 							.tz(
  // 								timeZone === "Africa/Cairo" && isSummerTime
  // 									? "Africa/Addis_Ababa"
  // 									: timeZone,
  // 								true
  // 							)
  // 							.format("dddd");
  // 						daysNames.push(dayName);
  // 					}

  // 					for (let dayNumber = 0; dayNumber <= 6; dayNumber++) {
  // 						recursiveSchedulesList.push({
  // 							fullDate: daysNames[dayNumber],
  // 							times: [],
  // 						});
  // 					}
  // 					let currentDayNumIndx = allSchedules.findIndex(
  // 						(schedule) =>
  // 							schedule.fullDate === moment(serverDate).format("DD-MM-YYYY")
  // 					);
  // 					var RecursivecurrentDayNumIndx = recursiveSchedulesList.findIndex(
  // 						(schedule) =>
  // 							schedule.fullDate === moment(serverDate).format("dddd")
  // 					);
  // 					for (
  // 						let dayNumber = currentDayNumIndx;
  // 						dayNumber <= currentDayNumIndx + 6;
  // 						dayNumber++
  // 					) {
  // 						if (allSchedules[dayNumber]?.times?.length) {
  // 							allSchedules[dayNumber].times.forEach((time) => {
  // 								if (
  // 									time.isRecursive &&
  // 									!recursiveSchedulesList[
  // 										RecursivecurrentDayNumIndx
  // 									]?.times?.includes(time)
  // 								) {
  // 									recursiveSchedulesList[
  // 										RecursivecurrentDayNumIndx
  // 									].times.push(time);
  // 								}
  // 							});
  // 							RecursivecurrentDayNumIndx++;
  // 						}
  // 					}
  // 					// 	res.data.data.schedules.forEach((schedule, scheduleIndex) => {
  // 					// 		if (schedule.times?.length) {
  // 					// 			schedule.times.forEach((time, timeIndex) => {
  // 					// 				allSchedules[scheduleIndex].times[timeIndex].startDateTime =
  // 					// 					allSchedules[scheduleIndex].times[
  // 					// 						timeIndex
  // 					// 					].startDateTime.replace("+02", "+03");
  // 					// 				allSchedules[scheduleIndex].times[timeIndex].endDateTime =
  // 					// 					allSchedules[scheduleIndex].times[
  // 					// 						timeIndex
  // 					// 					].endDateTime.replace("+02", "+03");
  // 					// 			});
  // 					// 		}
  // 					// 	});
  // 					axios({
  // 						method: "post",
  // 						url: baseURL + "/coache/EditAvailabilityNew",
  // 						data: {
  // 							id: coach._id,
  // 							schedulesNew: allSchedules,
  // 							recursiveSchedules: recursiveSchedulesList,
  // 							isFirstSessionFree: false,
  // 						},
  // 					}).then((res) => {});
  // 				});
  // 			}
  // 		});
  // 	});
  // };
  // useEffect(() => {
  // 	if (serverDate) {
  // 		getCoachList();
  // 	}
  // }, [serverDate]);
  // end test Mode
  // useEffect(() => {
  // 	if (serverDate && upcomingDate) {
  // 		moment(upcomingDate).tz(timeZone).format();
  // 		var now = moment(serverDate).tz(timeZone, true).format();
  // 		setInterval(() => {
  // 			now = moment(now).add(1, "seconds").format();

  // 			if (
  // 				now >=
  // 				moment(upcomingDate).tz(timeZone).subtract(5, "minutes").format()
  // 			) {
  // 				UpdateSessionStarted(true);
  // 			} else {
  // 				UpdateSessionStarted(false);
  // 			}
  // 		}, 1000);
  // 	}
  // }, [serverDate, upcomingDate, timeZone]);
  useEffect(() => {
    if (timeZone) {
      axios({
        method: "get",
        url: baseURL + `/account/GetServerTime?timeZone=${timeZone}`,
      }).then((res) => {
        UpdateServerDate(res.data);
        var timeNow = moment(res.data).format();
        var startTime = moment(upcomingDate)
          .tz(timeZone)
          .subtract(5, "minutes")
          .format();
        var endTime = moment(endUpcomingDate).tz(timeZone).format();
        setInterval(() => {
          timeNow = moment(timeNow).add(1, "seconds").format();
          if (startTime !== "Invalid date" && endTime !== "Invalid date") {
          }
        }, 1000);
      });
    }
    // eslint-disable-next-line
  }, [homeRerenderState]);

  // if (token) return <Redirect to={"/room/" + token} />;
  // if (token && redirectState) {
  // 	history.push(`/room/${token}?sessionId=${upcommingSessionID}`);
  // 	UpdateToken(null);
  // 	updateRedirectState(false);
  // }

  return (
    <div className="app" id="app">
      {stillPendingFlag && (
        <div className="pendingBox">Your account is still pending</div>
      )}
      {upcommingSessionID !== null && (
        <div className={"nextSessionCounterBox"}>
          {upcomingDate != null && upcomingDate.length > 0 && (
            <div
              className={
                sessionHight
                  ? "sessionCountdownContainer calendarSessionHeader"
                  : "sessionCountdownContainer"
              }
            >
              {props.auth.authData && (
                <div className="sessionCountdown">
                  You have an upcoming session
                  <br />
                  <CountDownTimer
                    dateTo={moment(upcomingDate)
                      // .subtract(
                      // 	timeZone === "Africa/Cairo" && isSummerTime ? 1 : 0,
                      // 	"hour"
                      // )
                      .tz(timeZone)
                      .format()}
                    dateFrom={moment(serverDate).tz(timeZone, true).format()}
                    callback={() => {}}
                  />
                  {/* } */}
                  {/* <div> */}
                  {upcommingSessionDetails && sessionDetailsPopup && (
                    <ViewSessionModal
                      getHasUnreadMsg={(val) => {
                        updateHasUnreadMsg(val);
                      }}
                      session={upcommingSessionDetails}
                      sessionType={
                        props.auth.authData &&
                        props.auth.authData.userType === 1
                          ? "client"
                          : "coach"
                      }
                      sessionDetailsPopup={sessionDetailsPopup}
                      handlePopup={handlePopup}
                    />
                  )}
                  <div className="d-flex justify-content-center">
                    {/* {!sessionStarted && */}
                    <button
                      onClick={handlePopup}
                      className="btn d-flex justify-content-center align-items-center"
                    >
                      View
                      {/* {hasUnreadMsg ? (
												<div className="red-dot mx-1"></div>
											) : (
												<></>
											)} */}
                    </button>
                    {/* } */}
                    {/* {sessionStarted && (
											<a
												href={token}
												target="_blank"
												without
												rel="noopener noreferrer">
												<button
													className={"btn sessionActive"}
													// onClick={() => {
													// 	history.push(token);
													// }}
												>
													Join
												</button>
											</a>
										)} */}
                  </div>
                  {/* </div> */}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <Route
        render={({ location }) => (
          <Routes
            auth={props.auth}
            location={location}
            getUpcommingSessionHight={getUpcommingSessionHight}
            serverDate={serverDate}
            updateHomeRerenderState={updateHomeRerenderState}
          />
        )}
      />
      <WelcomeModule />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    AuthState: (auth) => dispatch(AuthState(auth)),
    currencyActions: (currencyData) => dispatch(currencyActions(currencyData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
