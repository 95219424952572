import React, { Component } from "react";
import VidUploaderIcon from "../../../Components/svgLogos/VidUploaderIcon";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../App";
import Cross from "../../../Assets/PersonalInfoIconCross.png";
import { RegActions } from "../../../Store/Actions/RegActions";
import { Alert } from "react-bootstrap";
import { Row, Col, Button } from "antd";
import GuestNav from "../../../Components/GuestNav/GuestNav";
import "./DocAndVid.css";

class DocAndVid extends Component {
	state = {
		imgIDUploader: this.props.Reg.imgIDUploaderFile
			? this.props.Reg.imgIDUploaderFile.name
				? this.props.Reg.imgIDUploaderFile
				: null
			: null,
		docUploader: this.props.Reg.docUploaderFile
			? this.props.Reg.docUploaderFile.name
				? this.props.Reg.docUploaderFile
				: null
			: null,

		videoUploader: this.props.Reg.VidUploaderFile
			? this.props.Reg.VidUploaderFile.name
				? this.props.Reg.VidUploaderFile
				: null
			: null,
		submit: false,
		submitAttempt: false,
		errMsg: "*",
		uploadingState: false,
		uploadingProgress: 0,
		errorStates: [],
		loadingState: false,
	};

	fileSelectedHandler = (e) => {
		if (e.target.id === "videoUploader") {
			if (e.target.files[0].type.startsWith("video")) {
				this.setState(
					{
						[e.target.id]: e.target.files[0],
						uploadingState: true,
					},
					() => {
						const videoUploader = new FormData();

						videoUploader.append(
							"video",
							this.state.videoUploader,
							Math.floor(Math.random() * 1000000000000000) +
								"-" +
								this.state.videoUploader.name.split("-").join("")
						);

						axios
							.post(baseURL + "/user/videoUpload", videoUploader, {
								onUploadProgress: (data) => {
									//Set the progress value to show the progress bar
									this.setState({
										uploadingProgress: Math.ceil(
											(100 * data.loaded) / data.total
										),
									});
								},
							})
							.then((res) => {
								// then print response status
								this.props.RegActions({
									videoUploader: res.data.path,
								});
								this.setState({ uploadingState: false });
							});
					}
				);
			} else {
				alert("Please upload a video");
			}
		} else if (e.target.id === "imgIDUploader") {
			if (e.target.files[0].type.startsWith("image")) {
				this.setState(
					{
						[e.target.id]: e.target.files[0],
						loadingState: true,
					},
					() => {
						const imgIDUploader = new FormData();
						imgIDUploader.append(
							"image",
							this.state.imgIDUploader,
							Math.floor(Math.random() * 1000000000000000) +
								"-" +
								this.state.imgIDUploader.size +
								"-" +
								this.state.imgIDUploader.name
						);

						axios
							.post(baseURL + "/user/imageUpload", imgIDUploader, {
								// receive two parameter endpoint url ,form data
							})
							.then((res) => {
								// then print response status
								this.props.RegActions({
									imgIDUploader: res.data.path,
								});
								this.setState({ loadingState: false });
							});
					}
				);
			} else {
				alert("Please upload an image");
			}
		} else {
			if (
				e.target.files[0].type.includes("image") ||
				e.target.files[0].type.includes("pdf")
			) {
				this.setState(
					{
						[e.target.id]: e.target.files[0],
						loadingState: true,
					},
					() => {
						const docUploader = new FormData();
						docUploader.append(
							"file",
							this.state.docUploader,
							Math.floor(Math.random() * 1000000000000000) +
								"-" +
								this.state.docUploader.size +
								"-" +
								this.state.docUploader.name
						);
						axios
							.post(baseURL + "/user/fileUpload", docUploader, {
								// receive two parameter endpoint url ,form data
							})
							.then((res) => {
								// then print response status
								this.props.RegActions({
									docUploader: res.data.path,
								});
								this.setState({ loadingState: false });
							});
					}
				);
			} else {
				alert("Please upload Image or PDF files");
			}
		}
	};
	handleRemovePersonalID = () => {
		this.setState(
			{
				imgIDUploader: null,
			},
			() => this.props.RegActions({ imgIDUploader: null })
		);
	};
	handleRemoveDocUploader = () => {
		this.setState(
			{
				docUploader: null,
			},
			() => this.props.RegActions({ docUploader: null })
		);
	};
	handleRemoveVidUploader = () => {
		this.setState(
			{
				videoUploader: null,
			},
			() =>
				this.props.RegActions({ videoUploaderFile: null, videoUploader: null })
		);
	};
	handleSubmit = () => {
		if (
			(this.state.imgIDUploader || this.props.Reg.imgIDUploader) &&
			(this.state.docUploader || this.props.Reg.docUploader)
		) {
			this.setState({ submit: true });
		} else {
			let errorsVar = {
				personalId: this.state.imgIDUploader === null && "Personal ID",
				documents: this.state.docUploader === null && "Documents",
			};
			errorsVar = Object.values(errorsVar).filter((error) => error !== false);
			this.setState({
				errorStates: errorsVar,
			});
		}
	};

	render() {
		if (!this.props.Reg.specialization)
			return <Redirect to="/professionalinfo" />;
		if (this.state.submit === true) return <Redirect to="/prcandav" />;
		return (
			<div className="docAndVid authPage">
				<GuestNav progress={(100 / 6) * 5} total={6} />
				<Row className="flex-1">
					<Col xs={24} md={24} className="d-flex align-items-center">
						<div className="DocAndVidContainer authForm fullScreen">
							<div>
								<label>
									Personal ID
									<div className="uploadVidText">
										Please upload a copy of your personal ID to verify your
										identity.
									</div>
								</label>
								{this.state.submitAttempt &&
									this.state.imgIDUploader === null && (
										<span className="errMsg">{this.state.errMsg} </span>
									)}
							</div>
							<label className="uploadButton" htmlFor="imgIDUploader">
								Upload{" "}
							</label>
							{(this.state.imgIDUploader !== null ||
								this.props.Reg.imgIDUploader) && (
								<span className="itemName">
									{this.state.imgIDUploader?.name
										? this.state.imgIDUploader.name
										: this.props.Reg.imgIDUploader?.split("-")[2]}

									<img
										onClick={this.handleRemovePersonalID}
										id="personalIdCross"
										className="Cross"
										src={Cross}
										alt="Cross"
									/>
								</span>
							)}
							<input
								id="imgIDUploader"
								style={{ display: "none" }}
								type={"file"}
								onChange={this.fileSelectedHandler}
							/>
							<div>
								<label className="label">
									Documents
									{this.state.submitAttempt &&
										this.state.docUploader === null && (
											<span className="errMsg">{this.state.errMsg} </span>
										)}
									<div className="uploadVidText">
										Please upload relevant documents in regards to your
										professional experience, certifications or degree.
									</div>
								</label>
							</div>
							<label className="uploadButton" htmlFor="docUploader">
								Upload
							</label>
							{(this.state.docUploader !== null ||
								this.props.Reg.docUploader) && (
								<span className="itemName">
									{this.state.docUploader?.name
										? this.state.docUploader.name
										: this.props.Reg.docUploader?.split("-")[2]}
									<img
										onClick={this.handleRemoveDocUploader}
										id="docUploaderCross"
										className="Cross"
										src={Cross}
										alt="Cross"
									/>
								</span>
							)}
							<input
								id="docUploader"
								style={{ display: "none" }}
								type={"file"}
								multiple
								onChange={this.fileSelectedHandler}
							/>
							<div>
								<label className="label">Bio video</label>
							</div>
							<div className="uploadVidText">
								(Uploading a video can increase your booking rates by 80%)
							</div>
							{this.state.uploadingState ? (
								<div className="loaderContainer">
									<div className="loader"></div>
									<div className="loaderPercent">
										{this.state.uploadingProgress}%
									</div>
								</div>
							) : (
								<div id="videoUploaderContainer ">
									<label
										className="videoUploader text-center my-2 cursorPointer"
										htmlFor="videoUploader">
										<VidUploaderIcon />
									</label>
								</div>
							)}

							<input
								id="videoUploader"
								style={{ display: "none" }}
								type={"file"}
								accept="video/mp4,video/x-m4v,video/*"
								onChange={this.fileSelectedHandler}
							/>
							{!this.state.uploadingState &&
								(this.state.videoUploader !== null ||
									this.props.Reg.videoUploader) && (
									<span className="itemName mx-auto">
										{this.state.videoUploader?.name
											? this.state.videoUploader.name
											: this.props.Reg.videoUploader?.split("-")[1]}

										<img
											onClick={this.handleRemoveVidUploader}
											id="videoUploaderCross"
											className="Cross cursor-pointer"
											src={Cross}
											alt="Cross"
										/>
									</span>
								)}
							<div>
								<label>Video tips:</label>
							</div>
							<div className="tipsContainer text-left">
								<div>
									<span>1)</span> use natural light
								</div>
								<div>
									<span>2)</span>Eyes always on the camera lens
								</div>
								<div>
									<span>3)</span> 60 second limit
								</div>
							</div>
							{this.state.errorStates.length > 0 && (
								<Alert variant={"danger"} className="text-center">
									Please Fill The
									{this.state.errorStates.map((error, index) => (
										<>
											{" "}
											{error}
											{this.state.errorStates.length - 1 !== index && (
												<span>,</span>
											)}
										</>
									))}{" "}
									Fields
								</Alert>
							)}

							<div className="my-4 btnContainer">
								<div className="text-center">
									<Link to="/professionalInfo">
										<Button className="secondary-btn">Back</Button>
									</Link>
									<Button
										className="primary-btn"
										loading={
											this.state.uploadingState || this.state.loadingState
										}
										onClick={this.handleSubmit}>
										{this.state.uploadingState ? "Loading..." : "Next"}
									</Button>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={24} md={12} className={`authSolidSection d-none`}></Col>
				</Row>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		Reg: state.reg,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		RegActions: (reg) => dispatch(RegActions(reg)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(DocAndVid);
